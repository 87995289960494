import { useState } from 'react'
import { Navigate } from 'react-router-dom'

import { Box, Heading, Text } from '@chakra-ui/react'

//import { shopBuyItem } from '../lib/api-helper'

import Page from '../components/page'
import Background from '../components/background'
import ShopList from '../components/shop/list'
import ShopBuyItem from '../components/shop/buy-item'
import ShopViewItem from '../components/shop/view-item'
import { useRanContext } from '../components/ran-state-provider'

const Shop = () => {
  const { userBasicInfo, isAuthenticated, setToast } = useRanContext()

  const [pageTitle, setPageTitle] = useState(
    'Shop by all points type and all items.'
  )

  const [buyItem, setBuyItem] = useState(null)
  const [viewItem, setViewItem] = useState(null)

  const ShopHeader = () => (
    <Heading variant="main" fontSize={[32]} fontWeight={800} mb={5}>
      {userBasicInfo?.username}
      <Box fontSize={14} fontWeight={400}>
        <Text>E-Points: {userBasicInfo?.premiumPoints}</Text>
        <Text>Game Points: {userBasicInfo?.gamePoints}</Text>
      </Box>
    </Heading>
  )

  return !isAuthenticated ? (
    <Navigate to="/login" replace={true} />
  ) : (
    <>
      <Page title="Shop" header={<ShopHeader />} narrowProfile highProfile>
        <Box boxSize="full">
          <Text fontSize={14} mb={4}>
            {pageTitle}
          </Text>

          <ShopList
            setPageTitle={setPageTitle}
            onView={setViewItem}
            onBuy={setBuyItem}
          />
        </Box>
      </Page>

      {buyItem ? (
        <ShopBuyItem
          item={buyItem}
          setToast={setToast}
          onClose={() => {
            setBuyItem(null)
            setViewItem(null)
          }}
        />
      ) : viewItem ? (
        <ShopViewItem
          item={viewItem}
          onClose={() => setViewItem(null)}
          onBuy={() => {
            setBuyItem(viewItem)
            setViewItem(null)
          }}
        />
      ) : (
        <></>
      )}

      <Background highProfile />
    </>
  )
}

export default Shop
