import { useEffect, useState } from 'react'

import { FaShoppingCart } from 'react-icons/fa'

import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue
} from '@chakra-ui/react'

//import * as utils from '../lib/function-helper'
import { getPremiumPoints } from '../../lib/api-helper'

const TopUpList = ({ onSelect }) => {
  const itemBg = useColorModeValue('white', 'main-gray')
  const itemColor = useColorModeValue('black', 'white')

  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: []
  })

  useEffect(() => {
    async function fetchData() {
      const sessionTopUp = sessionStorage.getItem('topup')

      if (sessionTopUp) {
        setData(JSON.parse(sessionTopUp))
        setLoading(false)
        console.log('Cached topup data, loaded')
      } else await fetchInitialData()
    }

    fetchData()
  }, [])

  const fetchInitialData = async () => {
    const result = await getPremiumPoints()
    if (result !== null) {
      setData({ data: result.data })
      setLoading(false)
      sessionStorage.setItem('topup', JSON.stringify({ data: result.data }))
    }
  }

  return (
    <Box color="white" boxSize="full">
      <Text fontSize={14} mb={4}>
        (No Refund) All products are sold “as is”. You assume the responsibility
        for your purchase, and no refunds will be issued.
      </Text>
      <SimpleGrid minChildWidth={250} spacing={5}>
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          data.data.map((premiumPoint, index1) => (
            <Flex
              direction="column"
              bg={itemBg}
              color={itemColor}
              key={`topup-list-${index1}`}
            >
              <Box>
                <Image
                  boxSize="full"
                  objectFit="cover"
                  src={premiumPoint.imageLink}
                />
              </Box>

              <Flex
                onClick={() => onSelect(premiumPoint.id)}
                cursor="pointer"
                p="15px"
                bg={itemBg}
                align="center"
                justify="space-between"
                _hover={{ color: 'maroon' }}
              >
                <Heading variant="main" fontSize={14} mr={2}>
                  {premiumPoint.name}
                </Heading>
                <FaShoppingCart />
              </Flex>
            </Flex>
          ))
        )}
      </SimpleGrid>
    </Box>
  )
}

export default TopUpList
