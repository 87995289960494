import { useEffect, useState } from 'react'

import {
  Box,
  Center,
  Flex,
  Image,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'

import { getSchoolSmallLogo, getSchoolImage } from '../lib/ran-helper'
import { getElectronicRoomsInfo } from '../lib/api-helper'

const ClubWars = () => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: {
      rooms: []
    }
  })

  useEffect(() => {
    async function fetchData() {
      await fetchInitialData()
    }

    fetchData()
  }, [])

  const fetchInitialData = async () => {
    const result = await getElectronicRoomsInfo()
    if (result !== null) {
      setData({ data: result.data })
    }
    setLoading(false)
  }

  return (
    <Box color="white">
      <Text fontSize={14} mb={4}>
        Club Wars
      </Text>
      <Box
        fontSize={12}
        pb={{ base: 2, md: 0 }}
        minH={70}
        w={{ base: 'full', md: 'fit-content' }}
        overflow="auto"
      >
        <Stack direction={{ base: 'row', md: 'column' }} spacing={5}>
          {isLoading ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            data.data.rooms.map((data, index) => (
              <Flex minW={140} key={`top-notcher-${index + 1}`}>
                <Box boxSize={50} mr={1}>
                  <Image src={getSchoolImage(data.regionId)} alt={data.class} />
                </Box>
                <Box flex={1}>
                  <Flex align="center" mb={-0.5}>
                    <Image
                      h="10px"
                      src={getSchoolSmallLogo(data.guildSchool)}
                      alt={data.school}
                      mr={2}
                    />
                    <Text flex={1} fontSize={14} fontWeight={700}>
                      {data.guildName}
                    </Text>
                  </Flex>
                  <p>
                    E-Room:{' '}
                    <span style={{ fontWeight: 700 }}>{data.school}</span>
                  </p>
                  <p>
                    Master:{' '}
                    <span style={{ fontWeight: 700 }}>{data.clubMaster}</span>
                  </p>
                  <p>
                    Members:{' '}
                    <span style={{ fontWeight: 700 }}>
                      {data.guildMembersCount}
                    </span>
                  </p>
                  <p>
                    Tax: <span style={{ fontWeight: 700 }}>{data.tax}</span>
                  </p>
                </Box>
              </Flex>
            ))
          )}
        </Stack>
      </Box>
    </Box>
  )
}

export default ClubWars
