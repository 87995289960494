import { useState } from 'react'
import { Link as RouterLink, Navigate } from 'react-router-dom'

import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Modal,
  ModalOverlay,
  Spinner,
  Text
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'

import { userMaintenance } from '../lib/api-helper'

import Page from '../components/page'
import StyledInput from '../components/styled/input'
import StyledModalContent from '../components/styled/modal-content'
import { useRanContext } from '../components/ran-state-provider'

import backgroundOutline from '../assets/images/background-outline.png'
import logo from '../assets/images/logo.png'

const ForgotPassword = () => {
  const { isAuthenticated, setAuthentication, setToast } = useRanContext()

  const [isLoading, setLoading] = useState(false)
  const [inputVal, setInputVal] = useState({
    email: ''
  })

  const handleSubmit = async () => {
    if (isLoading) return
    setLoading(true)

    const onError = msgs => {
      setToast({
        status: 'error',
        messages: msgs
      })
      setLoading(false)
    }

    if (!inputVal.email) return onError(['Email address is required'])

    const result = await userMaintenance(
      'FORGOT_PASSWORD',
      inputVal,
      setAuthentication
    )

    if (result !== null || result !== undefined) {
      if (result.success) {
        setToast({
          status: 'success',
          messages: [
            'Password successfully sent to your registered email address.'
          ]
        })
        setInputVal({ email: '' })
        setLoading(false)
      } else onError(result.errors)
    } else {
      if (result.status === 404 || result.status === 400) onError(result.errors)
    }
  }

  const ForgotPasswordHeader = () => (
    <Heading variant="main" fontSize={[32]} fontWeight={800} mb={5}>
      SERVER INFO
    </Heading>
  )

  return isAuthenticated ? (
    <Navigate to="/account" replace={true} />
  ) : (
    <>
      <Page header={<ForgotPasswordHeader />} narrowProfile highProfile>
        <Modal isOpen={true} isCentered>
          <ModalOverlay
            bgImage={backgroundOutline}
            bgPosition="center"
            bgSize="cover"
          />
          <StyledModalContent
            display="flex"
            justifyContent="space-between"
            h={['calc(90vh - 60px)', 'calc(100vh - 60px)']}
            minH={600}
            maxH={840}
            py={[37, 50]}
            px={[6, 37]}
          >
            <Flex flexDirection="column" align="center" w="full">
              <Link
                as={RouterLink}
                to="/"
                position="absolute"
                w="full"
                fontSize={14}
                pl={[6, 37]}
                opacity={0.8}
                _hover={{ opacity: 1 }}
              >
                <ArrowBackIcon />
                Home
              </Link>
              <Image src={logo} maxW={140} />
              <Heading fontSize={24} mt={4} mb={6} align="center">
                Password Recovery via Email
              </Heading>
              <StyledInput
                value={inputVal?.email}
                onChange={e => setInputVal({ email: e.target.value })}
                placeholder="EMAIL ADDRESS"
                type="email"
                mb={3}
              />
              <Button onClick={handleSubmit} variant="maroon">
                {isLoading ? <Spinner /> : '> SUBMIT'}
              </Button>
            </Flex>
            <Box align="center" fontSize={14}>
              <Text>
                Log in your account?{' '}
                <Link as={RouterLink} to="/login">
                  {'> Log in'}
                </Link>
              </Text>
            </Box>
          </StyledModalContent>
        </Modal>
      </Page>
    </>
  )
}

export default ForgotPassword
