import {
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper
} from '@chakra-ui/react'

const StyledNumberInput = props => (
  <NumberInput {...props}>
    <NumberInputField
      p="28px"
      bg="#0e0e0e25"
      border="none"
      outline="none"
      borderRadius={0}
      color={props.value === props.placeholder ? '#22222298' : 'black'}
      fontSize={props.value === props.placeholder ? 12 : 14}
      letterSpacing={props.value === props.placeholder ? 3 : 0}
    />
    <NumberInputStepper>
      <NumberIncrementStepper color="maroon" />
      <NumberDecrementStepper color="maroon" />
    </NumberInputStepper>
  </NumberInput>
)

export default StyledNumberInput
