import { createContext, useContext, useState, useEffect } from 'react'
import { useToast, Text } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { isLogin } from '../lib/storage-helper'

const StyledText = styled(Text)`
  a {
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  a:hover {
    color: var(--chakra-colors-maroon);
  }
`

const RanStateContext = createContext()

const RanStateProvider = props => {
  const chakraToast = useToast()

  const [isAuthenticated, setAuthentication] = useState(isLogin())
  const [userBasicInfo, setUserBasicInfo] = useState({})
  const [toast, setToast] = useState({})

  useEffect(() => {
    toast?.messages?.forEach(msg =>
      chakraToast({
        description: (
          <StyledText dangerouslySetInnerHTML={{ __html: msg || '' }} />
        ),
        // Status e.g. success, error, warning & info
        status: toast?.status ? toast.status : 'info',
        isClosable: true
      })
    )
  }, [toast])

  const value = {
    isAuthenticated,
    setAuthentication,
    userBasicInfo,
    setUserBasicInfo,
    setToast
  }

  return (
    <RanStateContext.Provider value={value}>
      {props.children}
    </RanStateContext.Provider>
  )
}

const useRanContext = () => {
  const context = useContext(RanStateContext)
  if (!context)
    throw new Error(
      'useRanContext cannot be used outside of a RanStateProvider'
    )
  return context
}

export { RanStateProvider, useRanContext, RanStateContext }
