import { useState } from 'react'
import { Link as RouterLink, Navigate, useNavigate } from 'react-router-dom'

import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Modal,
  ModalOverlay,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'

import { userMaintenance } from '../lib/api-helper'

import Page from '../components/page'
import StyledInput from '../components/styled/input'
import StyledModalContent from '../components/styled/modal-content'
import { useRanContext } from '../components/ran-state-provider'

import backgroundOutline from '../assets/images/background-outline.png'
import logo from '../assets/images/logo.png'

const Register = () => {
  const navigate = useNavigate()
  const { isAuthenticated, setAuthentication, setToast } = useRanContext()

  const [page, setPage] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [inputVal, setInputVal] = useState({
    username: '',
    password: '',
    confirmPassword: '',
    email: '',
    pinCode: '',
    confirmPinCode: '',
    securityKey: '',
    referralCode: ''
  })

  const onError = msg => {
    setToast({
      status: 'error',
      messages: [msg].flat()
    })
  }

  const initialCheck = () => {
    const { username, password, confirmPassword, email } = inputVal

    if (!username) onError('Username is required')
    else if (!password) onError('Password is required')
    else if (!confirmPassword) onError('Confirm password is required')
    else if (!email) onError('Email address is required')

    if (!username || !password || !confirmPassword || !email) return false
    else return true
  }

  const handleSubmit = async () => {
    if (isLoading) return
    setLoading(true)

    const cancelSubmit = err => {
      if (err) onError(err)
      setLoading(false)
    }

    // Latter Check
    const { pinCode, confirmPinCode, securityKey } = inputVal

    if (!pinCode) onError('Pin code is required!')
    else if (!confirmPinCode) onError('Confirm pin code is required!')
    else if (!securityKey) onError('Security key is required!')

    if (!pinCode || !confirmPinCode || !securityKey) return cancelSubmit()

    const result = await userMaintenance(
      'REGISTER',
      inputVal,
      setAuthentication
    )

    if (result !== null || result !== undefined) {
      if (result.success) {
        setToast({
          status: 'success',
          messages: ['Account has been successfully created']
        })
        navigate('/login')
      } else cancelSubmit(result.errors)
    } else cancelSubmit(result.errors)
  }

  const RegisterHeader = () => (
    <Heading variant="main" fontSize={[32]} fontWeight={800} mb={5}>
      SERVER INFO
    </Heading>
  )

  return isAuthenticated ? (
    <Navigate to="/account" replace={true} />
  ) : (
    <>
      <Page header={<RegisterHeader />} narrowProfile highProfile>
        <Modal isOpen={true} isCentered>
          <ModalOverlay
            bgImage={backgroundOutline}
            bgPosition="center"
            bgSize="cover"
          />
          <StyledModalContent
            display="flex"
            justifyContent="space-between"
            h={['calc(90vh - 60px)', 'calc(100vh - 60px)']}
            minH={600}
            maxH={840}
            py={[37, 50]}
            px={[6, 37]}
          >
            <Flex flexDirection="column" align="center" w="full">
              <Link
                as={RouterLink}
                to="/"
                position="absolute"
                w="full"
                fontSize={14}
                pl={[6, 37]}
                opacity={0.8}
                _hover={{ opacity: 1 }}
              >
                <ArrowBackIcon />
                Home
              </Link>
              <Image src={logo} maxW={140} />

              {page === 0 ? (
                <>
                  <Heading fontSize={24} mt={4} mb={6} align="center">
                    Create an account
                  </Heading>
                  <StyledInput
                    value={inputVal?.username}
                    onChange={e =>
                      setInputVal({ ...inputVal, username: e.target.value })
                    }
                    placeholder="USERNAME"
                    type="text"
                    mb={3}
                  />
                  <StyledInput
                    value={inputVal?.password}
                    onChange={e =>
                      setInputVal({ ...inputVal, password: e.target.value })
                    }
                    placeholder="PASSWORD"
                    type="password"
                    mb={3}
                  />
                  <StyledInput
                    value={inputVal?.confirmPassword}
                    onChange={e =>
                      setInputVal({
                        ...inputVal,
                        confirmPassword: e.target.value
                      })
                    }
                    placeholder="CONFIRM PASSWORD"
                    type="password"
                    mb={3}
                  />
                  <StyledInput
                    value={inputVal?.email}
                    onChange={e =>
                      setInputVal({ ...inputVal, email: e.target.value })
                    }
                    placeholder="EMAIL ADDRESS"
                    type="email"
                    mb={6}
                  />
                  <Button
                    onClick={() => {
                      if (initialCheck()) setPage(1)
                    }}
                    variant="maroon"
                  >
                    {'> NEXT'}
                  </Button>
                </>
              ) : (
                <>
                  <Heading fontSize={24} mt={4} mb={6} align="center">
                    Lastly, fill out this extra fields:
                  </Heading>
                  <StyledInput
                    value={inputVal?.pinCode}
                    onChange={e =>
                      setInputVal({ ...inputVal, pinCode: e.target.value })
                    }
                    placeholder="PIN CODE"
                    type="password"
                    mb={3}
                  />
                  <StyledInput
                    value={inputVal?.confirmPinCode}
                    onChange={e =>
                      setInputVal({
                        ...inputVal,
                        confirmPinCode: e.target.value
                      })
                    }
                    placeholder="CONFIRM PIN CODE"
                    type="password"
                    mb={3}
                  />
                  <StyledInput
                    value={inputVal?.securityKey}
                    onChange={e =>
                      setInputVal({ ...inputVal, securityKey: e.target.value })
                    }
                    placeholder="SECURITY KEY"
                    type="password"
                    mb={3}
                  />
                  <StyledInput
                    value={inputVal?.referralCode}
                    onChange={e =>
                      setInputVal({ ...inputVal, referralCode: e.target.value })
                    }
                    placeholder="REFERRAL CODE (OPTIONAL)"
                    mb={6}
                  />
                  <Stack direction={['column', 'row']}>
                    <Button onClick={() => setPage(0)} variant="outline">
                      {isLoading ? <Spinner /> : '> BACK'}
                    </Button>
                    <Button onClick={handleSubmit} variant="maroon">
                      {isLoading ? <Spinner /> : '> SUBMIT'}
                    </Button>
                  </Stack>
                </>
              )}
            </Flex>
            <Box align="center" fontSize={14}>
              <Text>
                Already have an account?{' '}
                <Link as={RouterLink} to="/login">
                  {'> Log in'}
                </Link>
              </Text>
            </Box>
          </StyledModalContent>
        </Modal>
      </Page>
    </>
  )
}

export default Register
