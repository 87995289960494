import {
  Flex,
  Heading,
  Modal as ChakraModal,
  ModalOverlay
} from '@chakra-ui/react'

import StyledModalContent from '../components/styled/modal-content'
import StyledModalButtons from '../components/styled/modal-buttons'

const Modal = ({
  children,
  header,
  isLoading,
  isOpen,
  onClose,
  onSubmit,
  closeText,
  submitText,
  style,
  fitContent,
  buttonLess
}) => (
  <ChakraModal
    isOpen={isOpen}
    onClose={onClose}
    isCentered
    css={{ zIndex: -1 }}
  >
    <ModalOverlay />
    <StyledModalContent
      p={[6, 37]}
      {...style}
      maxW={fitContent ? 'fit-content' : 'md'}
      maxH="calc(100vh - 60px)"
      overflowY="auto"
    >
      <Flex flexDirection="column" align="center" w="full">
        <Heading fontSize={24} fontWeight={800} mb={6} align="center">
          {header}
        </Heading>

        {children}

        {buttonLess ? (
          <></>
        ) : (
          <StyledModalButtons
            isLoading={isLoading}
            onClose={onClose}
            onSubmit={onSubmit}
            closeText={closeText}
            submitText={submitText}
          />
        )}
      </Flex>
    </StyledModalContent>
  </ChakraModal>
)

export default Modal
