import { Button, Spinner, Stack } from '@chakra-ui/react'

const ModalButtons = ({
  isLoading,
  onClose,
  onSubmit,
  closeText,
  submitText
}) => (
  <Stack direction={['column-reverse', 'row']} pt={6}>
    <Button onClick={isLoading ? null : onClose} variant="outline">
      {'> ' + (closeText || 'BACK')}
    </Button>
    {onSubmit && (
      <Button onClick={isLoading ? null : onSubmit} variant="maroon">
        {isLoading ? <Spinner /> : '> ' + (submitText || 'SUBMIT')}
      </Button>
    )}
  </Stack>
)

export default ModalButtons
