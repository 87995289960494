import { useState } from 'react'

import { Center, Heading, Spinner } from '@chakra-ui/react'

import Page from '../components/page'
import Article from '../components/article'
import Background from '../components/background'

const ServerInfo = () => {
  const [isLoading /*, setLoading*/] = useState(false)
  const [data /*, setData*/] = useState({
    data: '<div class="panel-body"><p></p><h4>SERVER INFO:</h4>🔘 STRIFE SERVER <br>🔘 4 CLASS (BRAWLER/SWORDSMAN/ARCHER/SHAMAN) <br>🔘 210 MAX LEVEL <br>🔘 167 MAX SKILL <br>🔘 +10 MAX UPGRADE <br>🔘 CLASSIC RAN ONLINE GAMING <br>🔘 PARTY LEVELING <br>🔘 PURE HUNTING <br>🔘 FARM BASED <br>🔘 QUEST BASED <br>🔘 VERY LOW EXP RATE 💯 <br>🔘 VERY LOW DROP RATE 💯 <br>🔘 CLUB WARS (SCHEDULE: TUE/THUR/SAT)<br><br> <h4>FEATURES:</h4>🔘 CHARACTER PREVIEW <br>🔘 CHARACTER SIMPLIFY <br>🔘 IN-GAME REGISTRATION <br>🔘 IN-GAME ITEM SHOP (DISABLED IN WEBSITE) <br>🔘 HARDWARE BAN SYSTEM IMPLEMENTED <br><br><h4>MORE SERVERS TO BE ADDED IN THE FUTURE UNDER <b><i>KHLEA MANAGEMENT</i>!</b></h4><p></p></div>'
  })

  const ServerInfoHeader = () => (
    <Heading variant="main" fontSize={[32]} fontWeight={800} mb={5}>
      SERVER INFO
    </Heading>
  )

  return (
    <>
      <Page
        title="Server Info"
        header={<ServerInfoHeader />}
        narrowProfile
        highProfile
      >
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <Article
            title={'RAN Online PH Server Info'}
            subtitle={'Server Info'}
            innerHTML={data.data}
          />
        )}
      </Page>

      <Background highProfile />
    </>
  )
}

export default ServerInfo
