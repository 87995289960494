import { useEffect, useState } from 'react'

import {
  Box,
  Center,
  Flex,
  Image,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'

import { getSchoolSmallLogo, getClassImage } from '../lib/ran-helper'
import { getIndividualRankings } from '../lib/api-helper'

const IndividualRank = () => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: {
      topChars: []
    }
  })

  useEffect(() => {
    async function fetchData() {
      await fetchInitialData()
    }

    fetchData()
  }, [])

  const ranks = [
    'Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond', 'Crown', 'Grandmaster', 'Ace', 'Legendary'
  ]

  const fetchInitialData = async () => {
    const result = await getIndividualRankings()
    if (result !== null) {
      setData({ data: result.data })
      setLoading(false)
    }
  }

  return (
    <Box color="white">
      <Text fontSize={14} mb={4}>
        Top Notcher
      </Text>
      <Box
        fontSize={12}
        pb={{ base: 2, md: 0 }}
        minH={70}
        w={{ base: 'full', md: 'fit-content' }}
        overflow="auto"
      >
        <Stack direction={{ base: 'row', md: 'column' }} spacing={5}>
          {isLoading ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            data.data.topChars.map((data, index) => (
              <Flex minW={140} key={`top-notcher-${index + 1}`}>
                <Box boxSize={50} mr={1}>
                  <Image src={getClassImage(data.chaClass)} />
                </Box>
                <Box flex={1}>
                  <Flex align="center" mb={-0.5} flexWrap="wrap">
                    <Image
                      h="10px"
                      src={getSchoolSmallLogo(data.chaSchool)}
                      alt={data.schoolName}
                      mr={2}
                    />
                    <Text fontSize={14} fontWeight={700}>
                      {data.chaName}
                    </Text>
                  </Flex>
                  <p>
                    Level:{' '}
                    <span style={{ fontWeight: 700 }}>{data.chaLevel}</span>
                  </p>
                  <p>
                    Rank:{' '}
                    <span
                      style={{
                        color: 'var(--chakra-colors-main-green)',
                        fontWeight: 700
                      }}
                    >
                      {ranks[data.chaPkRank]}
                    </span>
                  </p>
                  <p>
                  Experience:{' '}
                    <span
                      style={{
                        color: '#c7e62c',
                        fontWeight: 700
                      }}
                    >
                      {data.chaPkExp}
                    </span>
                  </p>
                </Box>
              </Flex>
            ))
          )}
        </Stack>
      </Box>
    </Box>
  )
}

export default IndividualRank
