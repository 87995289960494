import { useEffect, useState } from 'react'
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom'

import { Button, Center, Heading, Link, Spinner } from '@chakra-ui/react'

import * as utils from '../lib/function-helper'
import { getNewsById } from '../lib/api-helper'

import Page from '../components/page'
import Article from '../components/article'
import Background from '../components/background'
import NewsList from '../components/news/list'

const News = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: {}
  })

  useEffect(() => {
    async function fetchData() {
      await fetchInitialData()
    }

    if (id) fetchData()
  }, [])

  const fetchInitialData = async () => {
    const result = await getNewsById({ id })

    if (result !== null) {
      if (result?.success === true) {
        setData(result.data)
        setLoading(false)
      } else navigate('/404', { replace: true })
    } else navigate('/404', { replace: true })
  }

  const NewsHeader = () => (
    <Heading variant="main" fontSize={[32]} fontWeight={800} mb={5}>
      NEWS
    </Heading>
  )

  return id ? (
    <>
      <Page title="News" header={<NewsHeader />} narrowProfile highProfile>
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <Article
            title={data.title}
            subtitle={
              <>
                <Link as={RouterLink} to="/news">
                  <Button variant="maroon-mini" display="inline-block">
                    {'< Back'}
                  </Button>
                </Link>{' '}
                &nbsp;
                {['News & Updates', 'Important Guides', 'Events'][data.type]}
              </>
            }
            innerHTML={data.text}
            createdDate={data.createdDate}
          />
        )}
      </Page>

      {!isLoading && (
        <Background src={utils.htmlToThumbnail(data.text)} highProfile />
      )}
    </>
  ) : (
    <>
      <Page title="News" header={<NewsHeader />} highProfile>
        <NewsList />
      </Page>

      <Background highProfile />
    </>
  )
}

export default News
