import { useState } from 'react'

import {
  Accordion,
  AccordionIcon,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  Center,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'

import StyledInput from '../../../styled/input'
import StyledTextarea from '../../../styled/text-area'
import StyledNumberInput from '../../../styled/number-input'
import StyledModalButtons from '../../../styled/modal-buttons'
import { useRanContext } from '../../../ran-state-provider'

import { createTicket, uploadImageToImgBB } from '../../../../lib/api-helper'

const CreateTicket = ({ onClose, setToast }) => {
  const { setAuthentication } = useRanContext()
  const [isLoading, setLoading] = useState(false)
  const [inputVal, setInputVal] = useState({
    amount: 0,
    message: '',
    file: '',
    fileName: '',
    referenceNumber: ''
  })

  const inputIsPass = () => {
    const { amount, message, referenceNumber, file } = inputVal

    const testAmount = !amount || amount < 100
    if (!file)
      setToast({
        status: 'error',
        messages: ['Proof of transaction is required']
      })
    else if (testAmount)
      setToast({ status: 'error', messages: ['Amount >= 100 is required'] })
    else if (!referenceNumber)
      setToast({ status: 'error', messages: ['Reference Number is required'] })
    else if (!message)
      setToast({ status: 'error', messages: ['Message is required'] })

    if (testAmount || !message || !referenceNumber || !file) return false
    else return true
  }

  const handleSubmit = async () => {
    if (isLoading) return
    if (inputIsPass()) setLoading(true)
    else return setLoading(false)

    const image = await uploadImageToImgBB(inputVal);

    const onError = () => {
      setToast({
        status: 'error',
        messages: result.errors.length
          ? result.errors
          : ['Something went wrong']
      })
      setLoading(false)
    }

    if(image === null)
    {
        inputVal.fileName = "not uploaded";
    }
    else
    {
        inputVal.fileName = image.url;
    }
    
    const result = await createTicket(inputVal, setAuthentication)

    if (result !== null || result !== undefined) {
      if (result.success) {
        setToast({
          status: 'success',
          messages: ['Ticket has been successfully created!']
        })
        onClose()
      } else onError()
    } else onError()
      

    // if (image !== null) {
    // } else {
    //   setToast({ status: 'error', messages: ['Image file upload failed'] })
    //   setLoading(false)
    // }
  }

  return (
    <>
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <>
          <Accordion w="full" allowToggle mb={3} outline="1px solid black">
            <AccordionItem>
              <AccordionButton>
                <Box as="span" flex={1} textAlign="left">
                  <b>Important Message</b>
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel align="center">
                <b>
                  "TOPUP ARE PURELY VOLUNTARY, NON-COMPULSORY, NON-TRANSFERABLE
                  AND NON-REFUNDABLE."
                </b>{' '}
                <br />
                <b>
                  <i>1 PHP = 1 E-POINT</i>
                </b>{' '}
                <br />
                <b>MINIMUM AMOUNT IS 100 PHP</b> <br />
                <br />
                <b>
                  NO NEED TO INDICATE A MESSAGE WHEN SENDING YOUR PAYMENT.
                </b>{' '}
                <br />
                <br />
                {/* ACCOUNT NUMBER: <b>09614839176</b> <br/>
        ACCOUNT NAME  : <b>BOBBY F.</b><br/> */}
                {/* ACCOUNT NUMBER: <b>09101562264</b> <br/>
        ACCOUNT NAME  : <b>JHENELL M.</b><br/> */}
                {/* ACCOUNT NUMBER: <b>09974493195</b> <br/>
        ACCOUNT NAME  : <b>HERMINIA L.</b><br/> */}
                ACCOUNT NUMBER #1: <b>09101562264</b>
                <br />
                ACCOUNT NAME #1: <b>JHENELL MENESES</b>
                <br />
                ACCOUNT NUMBER #2: <b>09762204009</b> <br />
                ACCOUNT NAME #2: <b>HERMINIA LLORENTE</b>
                <br />
                ACCOUNT NUMBER #3: <b>09761281087</b> <br />
                ACCOUNT NAME #3: <b>NICOLE LLORENTE</b>
                <br />
                <b>GCASH </b>
                <br />
                <br />
                ACCOUNT NUMBER: <b>09101562264</b> <br />
                ACCOUNT NAME : <b>JHENELL M.</b>
                <br />
                <b>PAYMAYA </b>
                <br />
                <br />
                EMAIL: <b>ranonlineph.official.bz@gmail.com</b>
                <br />
                <b>PAYPAL</b>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <Stack spacing={3} w="full">
            <Box bg="#0e0e0e25" py={2} px="28px">
              <Text fontSize={12} color="#22222298" letterSpacing={3}>
                PROOF OF TRANSACTION
              </Text>

              <input
                type="file"
                accept="image/*"
                onChange={e => {
                  setInputVal({ ...inputVal, file: e.target.files[0] })
                }}
              />
            </Box>

            <StyledNumberInput
              placeholder="AMOUNT"
              value={!inputVal.amount ? 'AMOUNT' : inputVal.amount}
              min={0}
              onChange={e =>
                setInputVal({ ...inputVal, amount: parseFloat(e) })
              }
            />

            <StyledInput
              value={inputVal?.referenceNumber}
              onChange={e =>
                setInputVal({ ...inputVal, referenceNumber: e.target.value })
              }
              name="file"
              placeholder="REFERENCE NUMBER"
            />

            <StyledTextarea
              value={inputVal?.message}
              onChange={e =>
                setInputVal({ ...inputVal, message: e.target.value })
              }
              placeholder="MESSAGE"
              type="password"
            />
          </Stack>
        </>
      )}

      <StyledModalButtons
        isLoading={isLoading}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export default CreateTicket
