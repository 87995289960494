import { useState, useEffect } from 'react'

import {
  Flex,
  NumberInput,
  NumberInputField,
  Stack,
  Text
} from '@chakra-ui/react'

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'

/*--- Doesn't start with 0 index, but with 1 as page number ---*/
const Pagination = ({ value, totalPages, onChange, lightMode }) => {
  /*--- State handler for NumberInput to display only ---*/
  const [inputVal, setInputVal] = useState(1)

  useEffect(() => setInputVal(value), [value])

  const handleChange = strVal => {
    setInputVal(strVal)
    const val = parseInt(strVal) || 0
    if (!val || val <= 0 || val > totalPages) return
    onChange(val)
  }

  const handleButtons = mode => {
    const initialValue = mode ? value + 1 : value - 1
    const newValue =
      initialValue <= 0
        ? 1
        : initialValue > totalPages
        ? totalPages
        : initialValue
    setInputVal(newValue)
    onChange(newValue)
  }

  return !totalPages ? (
    <></>
  ) : (
    <Stack
      align="center"
      direction="row"
      fontSize={24}
      color={lightMode ? 'white' : 'black'}
    >
      <ChevronLeftIcon cursor="pointer" onClick={() => handleButtons(0)} />
      <Flex align="center">
        <NumberInput
          value={inputVal}
          min={1}
          max={totalPages}
          onChange={handleChange}
        >
          <NumberInputField
            maxW={10}
            color={lightMode ? 'white' : 'black'}
            _placeholder={{ fontSize: 12, letterSpacing: 3 }}
            outline={`1px solid ${lightMode ? 'white' : 'black'}`}
            border="none"
            borderRadius={0}
            fontSize={14}
            letterSpacing={0}
            textAlign="center"
            p={0}
          />
        </NumberInput>
        <Text ml={2} fontSize={14}>
          / {totalPages}
        </Text>
      </Flex>
      <ChevronRightIcon cursor="pointer" onClick={() => handleButtons(1)} />
    </Stack>
  )
}

export default Pagination
