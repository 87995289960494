import { useLocation, Link as RouterLink } from 'react-router-dom'

import {
  Center,
  Flex,
  Image,
  Link,
  Spinner,
  Stack,
  useColorMode,
  useColorModeValue
} from '@chakra-ui/react'

import styled from '@emotion/styled'

import { SunIcon, MoonIcon } from '@chakra-ui/icons'

import { FaUserAlt, FaShoppingCart } from 'react-icons/fa'
import { FiLogOut } from 'react-icons/fi'

import logo from '../../assets/images/logo.png'

const StyledFlex = styled(Flex)`
  &:hover {
    .user-icon {
      opacity: 1 !important;
    }
  }
`

const NavContents = ({
  userBasicInfo,
  isAuthenticated,
  isLoading,
  onLogout
}) => {
  const location = useLocation()
  const { toggleColorMode } = useColorMode()

  const links = [
    { title: 'Home', to: '/' },
    { title: 'News', to: '/news' },
    { title: 'Downloads', to: '/downloads' },
    { title: 'Server Info', to: '/server-info' },
    { title: 'Rankings', to: '/rankings' }
    // { title: 'Top Up', to: '/top-up' }
  ]

  return (
    <Flex
      bg={useColorModeValue('white', 'black')}
      color={useColorModeValue('black', 'white')}
      fontSize={14}
      direction="column"
      justify="space-between"
      w={220}
      h="100vh"
      overflow="hidden"
      overflowY="auto"
    >
      <Stack spacing={10} p="40px" pt={50} w={220}>
        <Image src={logo} maxW={140} mx="auto" />
        {links.map(({ title, to }, i) => (
          <Link
            to={to}
            as={RouterLink}
            key={`nav-link-` + (i + 1)}
            fontWeight={400}
            _hover={{ textDecoration: 'none', opacity: 1 }}
            opacity={location.pathname === to ? 1 : 0.6}
          >
            {title}
          </Link>
        ))}

        <Link
          href="https://www.facebook.com/groups/ranonlinephmain2"
          target="_blank"
          fontWeight={400}
          opacity={0.5}
          _hover={{ textDecoration: 'none', opacity: 1 }}
        >
          Facebook Group
        </Link>
      </Stack>

      <Stack spacing={5} p="30px" pt={50} w={220}>
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <>
            {isAuthenticated && (
              <Link
                to="/shop"
                as={RouterLink}
                opacity={location.pathname === '/shop' ? 1 : 0.6}
                _hover={{ opacity: 1 }}
              >
                <Flex align="center" cursor="pointer">
                  <Center w="20px" fontSize={17} mr={4}>
                    <FaShoppingCart />
                  </Center>
                  Shop
                </Flex>
              </Link>
            )}

            <StyledFlex align="end" cursor="pointer">
              <Center
                className="user-icon"
                w="20px"
                fontSize={17}
                mr={4}
                opacity={location.pathname === '/account' ? 1 : 0.6}
              >
                <FaUserAlt />
              </Center>
              {isAuthenticated ? (
                <Link
                  to="/account"
                  as={RouterLink}
                  opacity={location.pathname === '/account' ? 1 : 0.6}
                  _hover={{ opacity: 1 }}
                >
                  {userBasicInfo?.username}
                </Link>
              ) : (
                <span>
                  <Link
                    to="/login"
                    as={RouterLink}
                    opacity={0.6}
                    _hover={{ opacity: 1 }}
                  >
                    Login
                  </Link>
                  {' / '}
                  <Link
                    to="/register"
                    as={RouterLink}
                    opacity={0.6}
                    _hover={{ opacity: 1 }}
                  >
                    Signup
                  </Link>
                </span>
              )}
            </StyledFlex>

            {isAuthenticated && (
              <Flex
                align="center"
                cursor="pointer"
                onClick={onLogout}
                opacity={0.6}
                _hover={{ opacity: 1 }}
              >
                <Center w="20px" fontSize={17} mr={4}>
                  <FiLogOut />
                </Center>
                Logout
              </Flex>
            )}
          </>
        )}
        <Flex
          onClick={toggleColorMode}
          cursor="pointer"
          opacity={0.6}
          _hover={{ opacity: 1 }}
          align="center"
        >
          {useColorModeValue(
            <>
              <Center>
                <MoonIcon fontSize={20} mr={4} />
              </Center>
              Dark mode
            </>,
            <>
              <Center>
                <SunIcon fontSize={20} mr={4} />
              </Center>
              Light mode
            </>
          )}
        </Flex>
      </Stack>
    </Flex>
  )
}

export default NavContents
