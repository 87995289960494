import {
  Box
  //  useColorModeValue
} from '@chakra-ui/react'

import { motion } from 'framer-motion'

const Background = ({ src, highProfile }) => {
  /*
  const highProfileBg = useColorModeValue(
    'linear-gradient(180deg, rgba(68, 68, 68, 0) 18.06%, rgba(68, 68, 68, 0.5) 29.51%, rgba(68, 68, 68, 0.75) 33.07%, #444444 44.88%)',
    'linear-gradient(180deg, rgba(18, 18, 18, 0) 18.06%, rgba(18, 18, 18, 0.5) 29.51%, rgba(18, 18, 18, 0.75) 33.07%, #121212 44.88%)'
  )
  const lowProfileBg = useColorModeValue(
    'linear-gradient(180deg, rgba(68, 68, 68, 0) 44.79%, #444444 65.62%)',
    'linear-gradient(180deg, rgba(18, 18, 18, 0) 44.79%, #121212 65.62%)'
  )
  */

  const highProfileBg =
    'linear-gradient(180deg, rgba(18, 18, 18, 0) 18.06%, rgba(18, 18, 18, 0.5) 29.51%, rgba(18, 18, 18, 0.75) 33.07%, #121212 44.88%)'
  const lowProfileBg =
    'linear-gradient(180deg, rgba(18, 18, 18, 0) 44.79%, #121212 65.62%)'

  const fadeBg = highProfile ? highProfileBg : lowProfileBg
  return (
    <>
      {/* Fade above div with background image */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4 }}
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          zIndex: 1
        }}
      >
        <Box bg={fadeBg} pointerEvents="none" boxSize="full" h="100vh" />
      </motion.div>

      {/* Div with background image */}
      {src && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.6 }}
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 0
          }}
        >
          <Box
            bgImage={src}
            bgSize="cover"
            bgPosition={highProfile ? 'top center' : 'center center'}
            bgRepeat="no-repeat"
            pointerEvents="none"
            boxSize="full"
            filter="brightness(0.5)"
            h="100vh"
          />
        </motion.div>
      )}
    </>
  )
}

export default Background
