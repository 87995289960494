import { useState } from 'react'
import { Link as RouterLink, Navigate } from 'react-router-dom'

import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  Modal,
  ModalOverlay,
  Spinner,
  Text
} from '@chakra-ui/react'
import { ArrowBackIcon } from '@chakra-ui/icons'

import * as utils from '../lib/function-helper'
import { authenticateUser } from '../lib/authenticate'

import Page from '../components/page'
import StyledInput from '../components/styled/input'
import StyledModalContent from '../components/styled/modal-content'
import { useRanContext } from '../components/ran-state-provider'

import backgroundOutline from '../assets/images/background-outline.png'
import logo from '../assets/images/logo.png'

const Login = () => {
  const { isAuthenticated, setAuthentication, setToast } = useRanContext()

  const [isLoading, setLoading] = useState(false)
  const [inputVal, setInputVal] = useState({
    username: '',
    password: ''
  })

  const handleSubmit = async () => {
    if (isLoading) return

    setLoading(true)
    const result = await authenticateUser(inputVal, setAuthentication)
    if (!result?.success) {
      setToast({
        status: 'error',
        messages: result.errors
      })
      setLoading(false)
    } else if (result?.success) {
      setToast({
        messages: [utils.getGreetings() + ', ' + inputVal?.username + '!']
      })
    }
  }

  const LoginHeader = () => (
    <Heading variant="main" fontSize={[32]} fontWeight={800} mb={5}>
      SERVER INFO
    </Heading>
  )

  return isAuthenticated ? (
    <Navigate to="/account" replace={true} />
  ) : (
    <>
      <Page header={<LoginHeader />} narrowProfile highProfile>
        <Modal isOpen={true} isCentered>
          <ModalOverlay
            bgImage={backgroundOutline}
            bgPosition="center"
            bgSize="cover"
          />
          <StyledModalContent
            display="flex"
            justifyContent="space-between"
            h={['calc(90vh - 60px)', 'calc(100vh - 60px)']}
            minH={600}
            maxH={840}
            py={[37, 50]}
            px={[6, 37]}
          >
            <Flex flexDirection="column" align="center" w="full">
              <Link
                as={RouterLink}
                to="/"
                position="absolute"
                w="full"
                fontSize={14}
                pl={[6, 37]}
                opacity={0.6}
                _hover={{ opacity: 1 }}
              >
                <ArrowBackIcon />
                Home
              </Link>
              <Image src={logo} maxW={140} />
              <Heading fontSize={24} mt={4} mb={6} align="center">
                Log in
              </Heading>
              <StyledInput
                value={inputVal?.username}
                onChange={e =>
                  setInputVal({ ...inputVal, username: e.target.value })
                }
                placeholder="USERNAME"
                type="text"
                mb={3}
              />
              <StyledInput
                value={inputVal?.password}
                onChange={e =>
                  setInputVal({ ...inputVal, password: e.target.value })
                }
                placeholder="PASSWORD"
                type="password"
                mb={6}
              />
              <Button onClick={handleSubmit} variant="maroon">
                {isLoading ? <Spinner /> : '> LOG IN'}
              </Button>
            </Flex>
            <Box align="center" fontSize={14}>
              <Text>
                Forgot password ?{' '}
                <Link as={RouterLink} to="/forgot-password">
                  {'> Recover'}
                </Link>
              </Text>
              <Text>
                Don't have an account yet?{' '}
                <Link as={RouterLink} to="/register">
                  {'> Sign up'}
                </Link>
              </Text>
            </Box>
          </StyledModalContent>
        </Modal>
      </Page>
    </>
  )
}

export default Login
