import { useState } from 'react'

import { Center, Spinner, Text } from '@chakra-ui/react'

import Modal from '../../modal'
import { useRanContext } from '../../ran-state-provider'

import { userMaintenance } from '../../../lib/api-helper'

const ForgotPin = ({ onClose, setToast }) => {
  const { setAuthentication } = useRanContext()
  const [isLoading, setLoading] = useState(false)

  const handleSubmit = async () => {
    if (isLoading) return
    setLoading(true)

    const result = await userMaintenance(
      'FORGOT_PIN_CODE',
      {},
      setAuthentication
    )

    const onError = () => {
      setToast({
        status: 'error',
        messages: result?.errors?.length
          ? result.errors
          : ['Something went wrong']
      })
      setLoading(false)
    }

    if (result !== null || result !== undefined) {
      if (result.success) {
        setToast({
          status: 'success',
          messages: ['PIN code has been sent to your registered email address']
        })
        onClose()
      } else onError()
    } else onError()
  }

  return (
    <Modal
      header="FORGOT PIN CODE"
      isOpen={true}
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={handleSubmit}
      SubmitText="RECOVER"
    >
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Text align="center">
          Press SUBMIT button to receive your PIN code in your registered email
          address
        </Text>
      )}
    </Modal>
  )
}

export default ForgotPin
