import { useEffect, useState } from 'react'

import { Link as RouterLink } from 'react-router-dom'

import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Spinner,
  Stack,
  Tag,
  Text,
  useColorModeValue
} from '@chakra-ui/react'

import * as utils from '../../lib/function-helper'
import { getNews, getNewsById } from '../../lib/api-helper'

const NewsList = () => {
  const itemBg = useColorModeValue('white', 'main-gray')
  const itemColor = useColorModeValue('black', 'white')

  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: {
      newsList: []
    }
  })
  const [thumbnails, setThumbnails] = useState([])

  useEffect(() => {
    async function fetchData() {
      const sessionNews = sessionStorage.getItem('news')
      const sessionThumbnails = sessionStorage.getItem('news-thumbnails')

      if (sessionNews && sessionThumbnails) {
        setData(JSON.parse(sessionNews))
        setThumbnails(JSON.parse(sessionThumbnails))
        setLoading(false)
        console.log('Cached news data, loaded')
      } else await fetchInitialData()
    }

    fetchData()
  }, [])

  const fetchInitialData = async () => {
    const result = await getNews()
    if (result !== null) {
      setData({ data: result.data })
      setLoading(false)
      sessionStorage.setItem('news', JSON.stringify({ data: result.data }))

      const resultThumb = []

      const iterateData = async (newsList, index, callback) => {
        if (newsList.length === index) return callback()

        const { id } = newsList[index].newsDisplayItem[0]
        const result = await getNewsById({ id })

        resultThumb.push(utils.htmlToThumbnail(result?.data?.text))

        return iterateData(newsList, index + 1, callback)
      }

      const callback = () => {
        setThumbnails(resultThumb)
        sessionStorage.setItem('news-thumbnails', JSON.stringify(resultThumb))
      }

      await iterateData(result.data.newsList, 0, callback)
    }
  }

  return (
    <Box boxSize="full">
      <SimpleGrid minChildWidth={250} spacing={5}>
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          /*--- For each News type ---*/
          data.data.newsList.map((data, index1) => (
            <Box color="white" key={`news-list-${index1 + 1}`}>
              <Text fontSize={14} mb={4}>
                {utils.titleCase(data.title)}
              </Text>
              <Box color={itemColor} fontSize={12} overflowY="auto">
                <Stack>
                  {/*--- Featured news with thumbnail ---*/}
                  {data.newsDisplayItem.slice(0, 1).map(newsItem => (
                    <Flex
                      direction="column"
                      bg={itemBg}
                      key={`news-list-${index1}-0`}
                    >
                      {/*--- Thumbnail ---*/}
                      <Box h={175}>
                        {thumbnails[index1] ? (
                          <Image
                            boxSize="full"
                            objectFit="cover"
                            src={thumbnails[index1]}
                          />
                        ) : (
                          <Center boxSize="full">
                            <Spinner />
                          </Center>
                        )}
                      </Box>

                      {/*--- News detail ---*/}
                      <Box p="15px">
                        <Heading variant="main" fontSize={14} mb="15px">
                          {newsItem.isNew && (
                            <Tag
                              size="sm"
                              p={1}
                              mr={1}
                              fontSize={10}
                              borderRadius={0}
                              colorScheme="yellow"
                            >
                              NEW
                            </Tag>
                          )}
                          {newsItem.displayTitle}
                        </Heading>
                        <Flex align="center" justify="space-between">
                          <RouterLink to={`/news/${newsItem.id}`}>
                            <Button variant="outline" mr={2}>
                              {'> READ MORE'}
                            </Button>
                          </RouterLink>
                          <Text>
                            {utils.formatShortDate(newsItem.dateCreated)}
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                  ))}

                  {/*--- News without thumbnail ---*/}
                  {data.newsDisplayItem.slice(1).map((newsItem, index2) => (
                    <RouterLink
                      to={`/news/${newsItem.id}`}
                      key={`news-list-${index1}-${index2}`}
                    >
                      <Flex
                        p="15px"
                        bg={itemBg}
                        _hover={{ color: 'maroon' }}
                        justify="space-between"
                      >
                        <Heading variant="main" fontSize={14} mr={2}>
                          {newsItem.isNew && (
                            <Tag
                              size="sm"
                              p={1}
                              mr={1}
                              fontSize={10}
                              borderRadius={0}
                              colorScheme="yellow"
                            >
                              NEW
                            </Tag>
                          )}
                          {newsItem.displayTitle}
                        </Heading>
                        <Text>
                          {utils.formatShortDate(newsItem.dateCreated)}
                        </Text>
                      </Flex>
                    </RouterLink>
                  ))}
                </Stack>
              </Box>
            </Box>
          ))
        )}
      </SimpleGrid>
    </Box>
  )
}

export default NewsList
