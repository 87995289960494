import { useEffect, useState } from 'react'

import {
  Center,
  Spinner,
  Flex,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react'

import Modal from '../../modal'
import Pagination from '../../pagination'
import { useRanContext } from '../../ran-state-provider'

import { getShopRecords } from '../../../lib/api-helper'

const View = ({ onClose, setToast }) => {
  const { setAuthentication } = useRanContext()
  const [isLoading, setLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [data, setData] = useState({
    data: {}
  })

  useEffect(() => {
    async function fetchData() {
      await fetchInitialData()
    }

    clearTimeout(localStorage.getItem('shop-records-timer'))
    const timer = setTimeout(fetchData, 100)
    localStorage.setItem('shop-records-timer', timer)
  }, [pageNumber])

  const fetchInitialData = async () => {
    if (isLoading) return
    setLoading(true)

    const result = await getShopRecords(pageNumber, setAuthentication)

    const onError = () => {
      setToast({
        status: 'error',
        messages: [
          result?.errors?.length ? result.errors : 'Something went wrong'
        ]
      })
      onClose()
    }

    if (result !== null) {
      if (result.status === 200) {
        setData(result.data)
        setLoading(false)
      } else onError()
    } else onError()
  }

  const getCurrency = data =>
    data.premPoints !== data.afterPremPoints ? 'E-POINTS' : 'GAME POINTS'

  const handlePageChange = page => {
    if (page === pageNumber) return
    setPageNumber(page)
  }

  return (
    <Modal
      header="SHOP RECORDS"
      isOpen={true}
      onClose={() => onClose()}
      fitContent={!isLoading || data?.dataList}
    >
      {isLoading || !data?.dataList ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <>
          <TableContainer>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Name</Th>
                  <Th>Item No.</Th>
                  <Th>Price</Th>
                  <Th>Latest Points</Th>
                  <Th>Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {(data?.dataList || []).map((item, index) => (
                  <Tr key={`shop-record-${index}`}>
                    <Td>{item.itemName}</Td>
                    <Td>{item.productNum}</Td>
                    <Td>
                      {item.itemPrice} {getCurrency(item)}
                    </Td>
                    <Td>
                      ( {item.afterPremPoints} ) E-POINTS | ({' '}
                      {item.afterVotePoints} ) GAME POINTS
                    </Td>
                    <Td>{item.displayDateBought}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

          {data?.dataList ? (
            !data.dataList.length && 'It seems empty here...'
          ) : (
            <></>
          )}

          <Flex mt={6} w="full" justifyContent="end">
            <Pagination
              value={pageNumber}
              totalPages={data?.totalPages || 0}
              onChange={handlePageChange}
            />
          </Flex>
        </>
      )}
    </Modal>
  )
}

export default View
