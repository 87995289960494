import { Link as RouterLink } from 'react-router-dom'
import HorizontalAdPreview from '../components/ad-sense/horizontal-ad-preview'

import {
  AspectRatio,
  Box,
  Button,
  Flex,
  Heading,
  Stack
} from '@chakra-ui/react'

import Page from '../components/page'
import NewsList from '../components/news/list'

const Home = () => {
  const HomeButtons = () => (
    <>
      <RouterLink to="/register">
        <Button variant="outline">{'> REGISTER'}</Button>
      </RouterLink>
      <RouterLink to="/downloads">
        <Button variant="maroon">{'> PLAY NOW'}</Button>
      </RouterLink>
    </>
  )

  const HomeHeader = () => (
    <Flex direction={{ base: 'column', md: 'row' }} justify="space-between">
      <Flex
        mr={2}
        maxH={200}
        maxW={382}
        direction="column"
        justify="space-between"
      >
        <Heading variant="main" fontSize={[32]} fontWeight={800} mb={5}>
          CONQUEROR'S PATH 2 TRAILER
        </Heading>

        <Stack display={{ base: 'none', md: 'flex' }} direction="row">
          <HomeButtons />
        </Stack>
      </Flex>

      <Box boxSize="full" maxW={490}>
        {/* Ratio of iframe video or Width/Height resolution */}
        <AspectRatio ratio={92 / 39} maxH={200}>
          <iframe
            src="https://www.youtube.com/embed/0coA3DSwtqY"
            title="RAN Online PH - Conqueror's Path 2 Trailer"
            style={{ width: '100%' }}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen=""
            frameBorder="0"
          ></iframe>
        </AspectRatio>
      </Box>

      <Stack display={{ base: 'flex', md: 'none' }} mt={5} direction="row">
        <HomeButtons />
      </Stack>
    </Flex>
  )

  return (
    <>
      <Page header={<HomeHeader />}>
        <HorizontalAdPreview />
        <NewsList />
      </Page>
    </>
  )
}

export default Home
