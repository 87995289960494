import { useState } from 'react'

import { Box, Center, Spinner, Text } from '@chakra-ui/react'

import Modal from '../../modal'
import StyledInput from '../../styled/input'
import { useRanContext } from '../../ran-state-provider'

import { userMaintenance } from '../../../lib/api-helper'

const PilotMode = ({ onClose, setToast }) => {
  const { userBasicInfo, setUserBasicInfo, setAuthentication } = useRanContext()
  const [isLoading, setLoading] = useState(false)
  const [inputVal, setInputVal] = useState({
    pin: ''
  })

  const handleSubmit = async () => {
    if (isLoading) return
    setLoading(true)

    const result = await userMaintenance(
      'PILOT_MODE',
      inputVal,
      setAuthentication
    )

    const onError = () => {
      setToast({
        status: 'error',
        messages: result?.errors?.length
          ? result.errors
          : ['Something went wrong']
      })
      setLoading(false)
    }

    if (result !== null || result !== undefined) {
      if (result?.success) {
        const { pilotMode } = result.data
        setUserBasicInfo({ ...userBasicInfo, pilotMode })
        setToast({
          status: 'success',
          messages: [
            `Pilot mode has been successfully ${
              pilotMode ? 'enabled' : 'disabled'
            }`
          ]
        })
        onClose()
      } else onError()
    } else onError()
  }

  return (
    <Modal
      header="PILOT MODE"
      isOpen={true}
      onClose={() => onClose()}
      onSubmit={handleSubmit}
      submitText={userBasicInfo.pilotMode ? 'DISABLE' : 'ENABLE'}
    >
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Box align="center">
          <Text mb={2}>
            PILOT MODE:{' '}
            <b>{userBasicInfo.pilotMode ? 'ENABLED' : 'DISABLED'}</b>
          </Text>
          <Text mb={6}>
            When <b>Pilot Mode</b> is enabled, your character will not be able
            to drop items, trade to other characters and buy items in the item
            shop. <b>Re-login</b> ingame your account after updating the Pilot
            Mode settings.
          </Text>
          <StyledInput
            value={inputVal?.pin}
            onChange={e => setInputVal({ ...inputVal, pin: e.target.value })}
            placeholder="PIN CODE"
            type="password"
          />
        </Box>
      )}
    </Modal>
  )
}

export default PilotMode
