import { useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import {
  Center,
  Flex,
  Link,
  Spinner,
  Text,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

import Pagination from '../../../pagination'
import StyledModalButtons from '../../../styled/modal-buttons'
import { useRanContext } from '../../../ran-state-provider'

import { getTickets } from '../../../../lib/api-helper'

const ListTicket = ({ onClose, onCreate, setToast }) => {
  const { setAuthentication } = useRanContext()
  const [pageNumber, setPageNumber] = useState(1)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState({
    data: {}
  })

  useEffect(() => {
    async function fetchData() {
      await fetchInitialData()
    }

    clearTimeout(localStorage.getItem('ticket-list-timer'))
    const timer = setTimeout(fetchData, 100)
    localStorage.setItem('ticket-timer', timer)
  }, [pageNumber])

  const fetchInitialData = async () => {
    if (isLoading) return
    setLoading(true)

    const result = await getTickets(pageNumber, setAuthentication)

    const onError = () => {
      setToast({
        status: 'error',
        messages: result?.errors?.length
          ? result.errors
          : ['Something went wrong']
      })
      setLoading(false)
    }

    if (result !== null) {
      if (result.status === 200) {
        setData(result.data)
        setLoading(false)
      } else onError()
    } else onError()
  }

  const handlePageChange = page => {
    if (page === pageNumber) return
    setPageNumber(page)
  }

  const getStatusColor = status => {
    switch (status) {
      case 'PENDING':
        return 'orange'
      case 'WAITING':
        return 'gold'
      case 'DONE':
        return 'green'
      case 'DISAPPROVED':
        return 'red'
      default:
        return 'auto'
    }
  }

  return (
    <>
      {isLoading || !data?.dataList ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <>
          <TableContainer>
            <Table size="sm">
              <Thead>
                <Tr>
                  <Th>Ticket No.</Th>
                  <Th>Status</Th>
                  <Th>Date Created</Th>
                </Tr>
              </Thead>
              <Tbody>
                {(data.dataList || []).map((item, index) => (
                  <Tr key={`help-desk-ticket-${index}`}>
                    <Td fontWeight={700}>
                      <Link
                        as={RouterLink}
                        to={`/account?tab=help-desk&id=${item.id}`}
                        _hover={{ color: 'maroon' }}
                      >
                        {item.id}
                        <ExternalLinkIcon ml={1} />
                      </Link>
                    </Td>
                    <Td fontWeight={700}>
                      <Text color={getStatusColor(item.status)}>
                        {item.status}
                      </Text>
                    </Td>
                    <Td>{item.fomattedCreatedDate}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          {data?.dataList ? (
            !data.dataList.length && 'It seems empty here...'
          ) : (
            <></>
          )}
        </>
      )}

      <Flex w="full" justifyContent="end" mt={6}>
        <Pagination
          value={pageNumber}
          totalPages={data?.totalPages || 0}
          onChange={handlePageChange}
        />
      </Flex>

      <StyledModalButtons
        onClose={onClose}
        onSubmit={onCreate}
        submitText="CREATE TICKET"
      />
    </>
  )
}

export default ListTicket
