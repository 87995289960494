import { Heading, Text } from '@chakra-ui/react'

import Page from '../components/page'
import Background from '../components/background'

const NotFound = () => {
  const NotFoundHeader = () => (
    <Heading variant="main" fontSize={[32]} fontWeight={800} mb={5}>
      404. Page Not Found
    </Heading>
  )

  return (
    <>
      <Page title="404" header={<NotFoundHeader />} narrowProfile highProfile>
        <Text fontSize={14} mb={4}>
          Our apologies. We're unable to find the page you're looking for.
        </Text>
      </Page>

      <Background highProfile />
    </>
  )
}

export default NotFound
