import { ModalContent } from '@chakra-ui/react'

const StyledModalContent = props => (
  <ModalContent
    bg="white"
    color="black"
    borderRadius={0}
    p={37}
    my={0}
    mx={5}
    fontSize={14}
    maxH={840}
    {...props}
  />
)

export default StyledModalContent
