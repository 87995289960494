import { useEffect, useState } from 'react'

import {
  Badge,
  Box,
  Center,
  Image,
  Flex,
  Stack,
  Spinner,
  Text,
  TableContainer,
  Table,
  Tbody,
  Tr,
  Td
} from '@chakra-ui/react'

import Modal from '../../modal'
import { useRanContext } from '../../ran-state-provider'

import { getUserAccountInfo } from '../../../lib/api-helper'
import { getClassImage, getSchoolSmallLogo } from '../../../lib/ran-helper'

const View = ({ onClose }) => {
  const { setUserBasicInfo, setAuthentication } = useRanContext()
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: {}
  })

  useEffect(() => {
    async function fetchData() {
      await fetchInitialData()
    }

    fetchData()
  }, [])

  const fetchInitialData = async () => {
    setLoading(true)

    const result = await getUserAccountInfo(setAuthentication)

    if (result !== null) {
      if (result.status === 200) {
        setUserBasicInfo(result.data)
        setData(result.data)
        setLoading(false)
      }
    }
  }

  return (
    <Modal
      header="ACCOUNT INFO"
      isOpen={true}
      onClose={() => onClose()}
      fitContent
    >
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <>
          <TableContainer>
            <Table size="sm">
              <Tbody>
                <Tr>
                  <Td>Username</Td>
                  <Td>
                    <b>{data.username}</b>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Email address</Td>
                  <Td>
                    <b>{data.email}</b>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Status</Td>
                  <Td>
                    <b>{data.status}</b>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Last Login Date</Td>
                  <Td>
                    <b>{data.lastLoginDate}</b>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Last Used IP Address</Td>
                  <Td>
                    <b>{data.lastUsedIPAddress}</b>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Referral Points</Td>
                  <Td>
                    <b>{data.referralPoints}</b>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <Text w="full" pl={4} py={1.5}>
            Characters on RAN Online PH:
            {!data.characters.length && (
              <>
                <br />
                ...It seems empty here
              </>
            )}
          </Text>
          <Stack w="full" px={4} columns={1} spacing={2}>
            {data.characters.map((data, index) => (
              <Flex key={`my-characters-${index + 1}`} align="center">
                <Box boxSize={50} mr={1}>
                  <Image src={getClassImage(data.classId)} alt={data.class} />
                </Box>
                <Box flex={1} fontSize={12}>
                  <Flex align="center" mb={-0.5}>
                    <Image
                      h="10px"
                      src={getSchoolSmallLogo(data.school)}
                      alt="school"
                      mr={2}
                    />
                    <Text flex={1} fontSize={14} fontWeight={700}>
                      {data.name}
                    </Text>
                    <Badge>{index + 1}</Badge>
                  </Flex>
                  <p>
                    Level: <b>{data.level}</b>
                    {' | '}
                    Gold:{' '}
                    <span
                      style={{
                        color: 'var(--chakra-colors-gold)',
                        fontWeight: 700
                      }}
                    >
                      {data.gold}
                    </span>
                    {' | '}
                    Status:{' '}
                    <span
                      style={{
                        color: `var(--chakra-colors-main-${
                          data.status === 'deleted' ? 'red' : 'green'
                        })`,
                        fontWeight: 700
                      }}
                    >
                      {data.status}
                    </span>
                  </p>
                </Box>
              </Flex>
            ))}
          </Stack>
        </>
      )}
    </Modal>
  )
}

export default View
