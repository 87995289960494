import { useEffect, useState } from 'react'

import { Center, Flex, Image, Spinner, Stack, Text } from '@chakra-ui/react'

import Modal from '../modal'
import StyledInput from '../styled/input'

import { topUpCheckOut, requestTopup } from '../../lib/api-helper'

const TopUpCheckout = ({ id, onClose, setToast }) => {
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState({
    data: {}
  })
  const [inputVal, setInputVal] = useState({
    username: '',
    email: ''
  })

  useEffect(() => {
    if (id === null) return

    async function fetchData() {
      await fetchInitialData()
    }

    fetchData()
  }, [id])

  const fetchInitialData = async () => {
    if (id === null) return
    setLoading(true)

    const result = await topUpCheckOut({ id })

    const onError = () => {
      setToast({
        status: 'error',
        messages: result?.errors?.length
          ? result.errors
          : ['Something went wrong']
      })
      onClose()
    }

    if (result !== null) {
      if (result.success === true) {
        const { autoFillUsername, autoFillEmail } = result.data

        setInputVal({ username: autoFillUsername, email: autoFillEmail })
        setData(result.data)
        setLoading(false)
      } else onError()
    } else onError()
  }

  const handleSubmit = async () => {
    if (isLoading) return
    setLoading(true)

    const result = await requestTopup({ id, ...inputVal })

    const onError = () => {
      setToast({
        status: 'error',
        messages: result?.errors?.length
          ? result.errors
          : ['Something went wrong']
      })
      setLoading(false)
    }

    if (result !== null || result !== undefined) {
      if (result.success === true) {
        window.location.href = result.data.xendit_checkout_link
      } else onError()
    } else onError()
  }

  return (
    <Modal
      header={
        !isLoading && (
          <>
            <Image maxH={150} mb="30px" src={data?.premiumPoints?.imageLink} />
            {data?.premiumPoints?.name}
          </>
        )
      }
      isLoading={isLoading}
      isOpen={id !== null}
      onClose={() => onClose()}
      onSubmit={handleSubmit}
      submitText="BUY"
    >
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Stack w="full" spacing={3}>
          <StyledInput
            value={inputVal?.username}
            onChange={e =>
              setInputVal({ ...inputVal, username: e.target.value })
            }
            placeholder="USERNAME"
            type="text"
          />
          <StyledInput
            value={inputVal?.email}
            onChange={e => setInputVal({ ...inputVal, email: e.target.value })}
            placeholder="EMAIL"
            type="email"
          />
          <Flex w="full" align="end" justify="space-between">
            <Text flex={1}>Amount to pay: </Text>
            <Text fontSize={24} fontWeight={800} color="black">
              PHP {data?.premiumPoints?.price}
            </Text>
          </Flex>
        </Stack>
      )}
    </Modal>
  )
}

export default TopUpCheckout
