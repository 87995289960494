import { useState, useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { Heading } from '@chakra-ui/react'

//import * as utils from '../lib/function-helper'

import Page from '../components/page'
import Background from '../components/background'
import TopUpList from '../components/top-up/list'
import TopUpCheckout from '../components/top-up/checkout'
import { useRanContext } from '../components/ran-state-provider'

const TopUp = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { setToast } = useRanContext()

  const [id, setId] = useState(null)

  useEffect(() => {
    setId(searchParams.get('id'))
  }, [location])

  const TopUpHeader = () => (
    <Heading variant="main" fontSize={[32]} fontWeight={800} mb={5}>
      Top Up
    </Heading>
  )

  return (
    <>
      <Page title="Top Up" header={<TopUpHeader />} highProfile>
        <TopUpList
          onSelect={id => {
            navigate(`/top-up?id=${id}`)
          }}
        />
      </Page>

      <Background highProfile />

      <TopUpCheckout
        id={id}
        onClose={() => {
          navigate('/top-up')
        }}
        setToast={setToast}
      />
    </>
  )
}

export default TopUp
