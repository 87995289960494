import { useEffect, useState } from 'react'

import { Center, Heading, SimpleGrid, Spinner } from '@chakra-ui/react'

import { getDownloads } from '../lib/api-helper'

import Page from '../components/page'
import Article from '../components/article'
import Background from '../components/background'

const Downloads = () => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: {
      downloads: []
    }
  })

  useEffect(() => {
    async function fetchData() {
      await fetchInitialData()
    }

    fetchData()
  }, [])

  const fetchInitialData = async () => {
    const result = await getDownloads()

    console.log(result)
    if (result !== null) {
      setData({ data: result.data })
      setLoading(false)
    }
  }

  const DownloadsHeader = () => (
    <Heading variant="main" fontSize={[32]} fontWeight={800} mb={5}>
      DOWNLOADS
    </Heading>
  )

  return (
    <>
      <Page title="Downloads" header={<DownloadsHeader />} highProfile>
        {isLoading ? (
          <Center>
            <Spinner />
          </Center>
        ) : (
          <SimpleGrid minChildWidth={320} spacing="20px">
            {data.data.downloads.map((data, index) => (
              <Article
                key={`downloads-${index + 1}`}
                title={data.title}
                subtitle={'Downloads'}
                innerHTML={data.text}
                createdDate={data.createdDate}
              />
            ))}
          </SimpleGrid>
        )}
      </Page>
      <Background highProfile />
    </>
  )
}

export default Downloads
