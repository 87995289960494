import { useEffect, useState } from 'react'

import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  Image,
  Spinner,
  Stack,
  Text
} from '@chakra-ui/react'

import StyledTextarea from '../../../styled/text-area'
import StyledModalButtons from '../../../styled/modal-buttons'
import { useRanContext } from '../../../ran-state-provider'

import { getTicketById, replyTicket } from '../../../../lib/api-helper'

const ViewTicket = ({ id, onClose, setToast }) => {
  const { setAuthentication } = useRanContext()
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState({
    data: {}
  })
  const [inputVal, setInputVal] = useState('')

  useEffect(() => {
    async function fetchData() {
      await fetchInitialData()
    }

    fetchData()
  }, [])

  const fetchInitialData = async () => {
    if (isLoading) return
    setLoading(true)

    const result = await getTicketById({ id }, setAuthentication)

    const onError = () => {
      setToast({
        status: 'error',
        messages: result?.errors?.length
          ? result.errors
          : ['Something went wrong']
      })
      setLoading(false)
      onClose()
    }

    if (result !== null) {
      if (result.status === 200) {
        setData(result.data)
        setLoading(false)
      } else onError()
    } else onError()
  }

  const handleSubmit = async () => {
    if (isLoading) return
    setLoading(true)

    const result = await replyTicket(
      { ticketId: id, message: inputVal },
      setAuthentication
    )

    const onError = () => {
      setToast({
        status: 'error',
        messages: result?.errors?.length
          ? result.errors
          : ['Something went wrong']
      })
      setLoading(false)
    }

    if (result !== null || result !== undefined) {
      if (result.success) {
        setLoading(false)
        setInputVal('')
        await fetchInitialData()
      } else onError()
    } else onError()
  }

  const getStatusColor = status => {
    switch (status) {
      case 'PENDING':
        return 'orange'
      case 'WAITING':
        return 'gold'
      case 'DONE':
        return 'green'
      case 'DISAPPROVED':
        return 'red'
      default:
        return 'auto'
    }
  }

  return (
    <>
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <>
          <Box w="full" align="left">
            <Text>
              Status:{' '}
              <span
                style={{
                  color: `var(--chakra-colors-${getStatusColor(
                    data.formattedStatus
                  )})`
                }}
              >
                <b>{data.formattedStatus}</b>
              </span>
            </Text>
            <Text>
              Amount: <b>{data.amount}</b>
            </Text>
            <Accordion w="full" allowToggle defaultIndex={2}>
              <AccordionItem>
                <AccordionButton p={0} borderBottom="1px solid #22222298">
                  <Box as="span" flex={1} textAlign="left" fontSize={14}>
                    Message:
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel px={0}>
                  <Text bg="#0e0e0e25" p={3}>
                    {data.message}
                  </Text>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton p={0} borderBottom="1px solid #22222298">
                  <Box as="span" flex={1} textAlign="left" fontSize={14}>
                    Proof of Transaction:
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel px={0}>
                  {data.image ? (
                    <Image bg="#0e0e0e25" p={3} src={data.image} alt="PoT" />
                  ) : (
                    'N/A'
                  )}
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton p={0} borderBottom="1px solid #22222298">
                  <Box as="span" flex={1} textAlign="left" fontSize={14}>
                    Reply Thread:
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel px={0}>
                  <Stack
                    bg="#0e0e0e25"
                    p={3}
                    mb={3}
                    maxH="40vh"
                    overflowY="auto"
                    direction="column-reverse"
                  >
                    {!data?.replyToDonations?.length ? (
                      <Text>
                        <small>
                          There are currently no replies yet on your Ticket #
                          {id}
                        </small>
                      </Text>
                    ) : (
                      data.replyToDonations
                        .sort(
                          ({ createdDate: a }, { createdDate: b }) =>
                            new Date(a) - new Date(b)
                        )
                        .reverse()
                        .map(
                          (
                            { sentBy, formattedCreatedDate, message },
                            index
                          ) => (
                            <Box
                              key={`ticket-reply-${index}`}
                              align={
                                sentBy === '@Administrator' ? 'left' : 'right'
                              }
                            >
                              <Text>
                                <small>
                                  <b>
                                    {sentBy === '@Administrator'
                                      ? 'Administrator'
                                      : data.userName}
                                  </b>{' '}
                                  replied on {formattedCreatedDate}
                                </small>
                              </Text>
                              <Box
                                dangerouslySetInnerHTML={{ __html: message }}
                              />
                            </Box>
                          )
                        )
                    )}
                  </Stack>

                  {data.formattedStatus !== 'DONE' &&
                    data.formattedStatus !== 'DISAPPROVED' && (
                      <>
                        <StyledTextarea
                          w="full"
                          value={inputVal}
                          onChange={e => setInputVal(e.target.value)}
                          placeholder="MESSAGE"
                        />
                        <Flex justify="end">
                          <Button variant="maroon-mini" onClick={handleSubmit}>
                            SEND
                          </Button>
                        </Flex>
                      </>
                    )}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </Box>
        </>
      )}

      <StyledModalButtons onClose={onClose} />
    </>
  )
}

export default ViewTicket
