import { useEffect, useState } from 'react'

import { Box, Center, Spinner, Text } from '@chakra-ui/react'

import Modal from '../../modal'
import { useRanContext } from '../../ran-state-provider'

import { userMaintenance, getUserGameTime } from '../../../lib/api-helper'

const GametimeGamePoints = ({ onClose, setToast }) => {
  const { userBasicInfo, setUserBasicInfo, setAuthentication } = useRanContext()
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState({
    collectedHours: 0,
    collectedMinutes: 0,
    gamePointsToGet: 0,
    requiredGameTimeHours: 1,
    gameTimeMinuteToPoint: 1,
    conversionLevelRequirement: 60
  })

  useEffect(() => {
    async function fetchData() {
      await fetchInitialData()
    }

    fetchData()
  }, [])

  const fetchInitialData = async () => {
    setLoading(true)

    const result = await getUserGameTime(setAuthentication)

    if (result !== null) {
      if (result.status === 200) {
        setData(result.data)
        setLoading(false)
      }
    }
  }

  const handleSubmit = async () => {
    if (isLoading) return
    setLoading(true)

    const result = await userMaintenance(
      'GAMETIME_GAMEPOINTS',
      {},
      setAuthentication
    )

    const onError = () => {
      setToast({
        status: 'error',
        messages: result?.errors?.length
          ? result.errors
          : ['Something went wrong']
      })
      setLoading(false)
    }

    if (result !== null || result !== undefined) {
      if (result.success) {
        const { gamePoints } = result.data
        setUserBasicInfo({ ...userBasicInfo, gamePoints })
        setToast({
          status: 'success',
          messages: ['GameTime has been successfully converted']
        })
        onClose()
      } else onError()
    } else onError()
  }

  return (
    <Modal
      header="GAMETIME TO GAMEPOINTS"
      isOpen={true}
      onClose={() => onClose()}
      onSubmit={handleSubmit}
    >
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Box align="center">
          <Text mb={2}>
            Collected GameTime: <b>{data.collectedHours}</b> Hour
            {data.collectedHours > 1 ? 's' : ''} &{' '}
            <b>{data.collectedMinutes}</b> Minute
            {data.collectedMinutes > 1 ? 's' : ''}. You will need a Level{' '}
            <b>{data.conversionLevelRequirement}</b> character before you start
            farming game
          </Text>
          <Text>
            At this time you will get ( <b>{data.gamePointsToGet}</b> ) Game
            Points
            <br />
            <b>{data.requiredGameTimeHours}</b> Hour
            {data.requiredGameTimeHours > 1 ? 's' : ''} ={' '}
            <b>{data.gameTimeMinuteToPoint}</b> Game Point
            {data.gameTimeMinuteToPoint > 1 ? 's' : ''}
          </Text>
        </Box>
      )}
    </Modal>
  )
}

export default GametimeGamePoints
