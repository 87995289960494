import { Route, Routes, useLocation, Navigate } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import Home from './compositions/home'
import News from './compositions/news'
import Downloads from './compositions/downloads'
import ServerInfo from './compositions/server-info'
import Rankings from './compositions/rankings'
import TopUp from './compositions/top-up'

import Login from './compositions/login'
import ForgotPassword from './compositions/forgot-password'
import Register from './compositions/register'

import Account from './compositions/account'
import Shop from './compositions/shop'

import NotFound from './compositions/404'

const AnimatedRoutes = () => {
  const location = useLocation()

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        {/*--- Public & Authenticated routes ---*/}
        <Route path="/" element={<Home />} />
        <Route path="/news/:id?" element={<News />} />
        <Route path="/downloads" element={<Downloads />} />
        <Route path="/server-info" element={<ServerInfo />} />
        <Route path="/rankings" element={<Rankings />} />
        <Route path="/top-up" element={<TopUp />} />

        {/*--- Public only routes ---*/}
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/register" element={<Register />} />

        {/*--- Authenticated only routes ---*/}
        <Route path="/account" element={<Account />} />
        <Route path="/shop" element={<Shop />} />

        {/*--- End of routes, always 404 ---*/}
        <Route path="/404" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/404" replace={true} />} />
      </Routes>
    </AnimatePresence>
  )
}

export default AnimatedRoutes
