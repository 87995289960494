import axios from 'axios'
import { serverAPIUrl, apiEndPoints } from './api-config'
import { saveTokens, saveLoginStatus } from './storage-helper'

export const authenticateUser = async (param, setAuthentication) => {
  try {
    axios.defaults.withCredentials = true
    var result = await axios.post(
      serverAPIUrl + apiEndPoints.authenticateUser,
      param,
      { withCredentials: true }
    )

    if (result.status === 200) {
      // successful authentication, save the tokens here.
      saveTokens(result.data.data)
      saveLoginStatus(true)

      setAuthentication(true)

      return {
        success: true,
        data: result.data.data
      }
    }
  } catch (error) {
    var data = { ...error }

    if (data.response === undefined) {
      return null
    }

    if (data !== null && data !== undefined && data.response.status === 400) {
      return {
        success: false,
        errors: data.response.data.error
      }
    }
  }
}
