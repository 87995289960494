import { Box, Flex, Heading, Text, Stack } from '@chakra-ui/react'

import { TwitterShareButton, FacebookShareButton } from 'react-share'
import { FaTwitter, FaFacebook, FaLink } from 'react-icons/fa'

import styled from '@emotion/styled'

import * as utils from '../lib/function-helper'

const StyledBox = styled(Box)`
  word-wrap: break-word;

  a {
    text-decoration: underline;
    text-underline-offset: 3px;
  }

  a:hover {
    color: var(--chakra-colors-maroon);
  }

  p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 1rem;
  }

  p > img {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
  }

  h4 {
    font-size: 16px;
    font-weight: 700;
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
  }

  h2 {
    font-size: 19px;
    font-weight: 700;
  }

  h1 {
    font-size: 20px;
    font-weight: 700;
  }
`

const Article = ({ subtitle, title, innerHTML, createdDate }) => {
  /* Share local host to SocMed does not work */
  const currentUrl = window.location.href

  const shareProps = {
    url: currentUrl,
    hashtag: '#RanOnlinePH'
  }

  const copyUrlToClipboard = () => {
    var dummy = document.createElement('input')

    document.body.appendChild(dummy)
    dummy.value = currentUrl
    dummy.select()
    document.execCommand('copy')
    document.body.removeChild(dummy)
  }

  return (
    <Box boxSize="full">
      <Text fontSize={14} mb={4}>
        {subtitle}
      </Text>
      <Heading variant="main" fontSize={32} fontWeight={800} mb={-2}>
        {title}
        <Text fontSize={16} fontWeight={400} mb={2}>
          {createdDate ? utils.formatLongDate(createdDate) : null}
          {createdDate && innerHTML && ' · '}
          {innerHTML ? utils.htmlToMinuteRead(innerHTML) : null}
        </Text>
        <Stack direction="row" fontSize={16}>
          <TwitterShareButton {...shareProps}>
            <FaTwitter />
          </TwitterShareButton>

          <FacebookShareButton {...shareProps}>
            <FaFacebook />
          </FacebookShareButton>

          <FaLink cursor="pointer" onClick={copyUrlToClipboard} />
        </Stack>
      </Heading>
      <Flex flexWrap="wrap">
        <Text fontSize={64} fontWeight={800} w={{ base: 'full', sm: 'auto' }}>
          &lsquo;&lsquo;
        </Text>
        <StyledBox
          flex={{ base: null, sm: 1 }}
          w="full"
          my={{ base: -10, sm: 7 }}
          mx={{ base: 0, sm: 2 }}
          dangerouslySetInnerHTML={{ __html: innerHTML || '' }}
        />
        <Text
          fontSize={64}
          fontWeight={800}
          w={{ base: 'full', sm: 'auto' }}
          transform="rotate(180deg)"
        >
          &lsquo;&lsquo;
        </Text>
      </Flex>
    </Box>
  )
}

export default Article
