import { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Center,
  Spinner,
  Stack,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react'

import Modal from '../../modal'
import StyledInput from '../../styled/input'
import { useRanContext } from '../../ran-state-provider'

import { createWhitelist, getWhitelists } from '../../../lib/api-helper'

const IPWhitelists = ({ onClose, setToast }) => {
  const { setAuthentication } = useRanContext()
  const [isLoading, setLoading] = useState(false)
  /*--- Mode (0) for delete, (1) for add ---*/
  const [mode, setMode] = useState(1)
  const [data, setData] = useState([])
  const [inputVal, setInputVal] = useState({
    pinCode: '',
    ipAddress: ''
  })

  useEffect(() => {
    async function fetchData() {
      await fetchInitialData()
    }

    fetchData()
  }, [])

  const fetchInitialData = async () => {
    if (isLoading) return
    setLoading(true)

    const result = await getWhitelists(setAuthentication)

    if (result !== null) {
      if (result.status === 200) {
        setData(result.data)
        setLoading(false)
      }
    }
  }

  const handleSubmit = async () => {
    if (isLoading) return
    setLoading(true)

    const result = await createWhitelist(
      { ...inputVal, delete: mode === 0 },
      setAuthentication
    )

    const onError = () => {
      setToast({
        status: 'error',
        messages: result?.errors?.length
          ? result.errors
          : ['Something went wrong']
      })
      setLoading(false)
    }

    if (result !== null || result !== undefined) {
      if (result?.success) {
        setData(result.data)
        setInputVal({
          pinCode: '',
          ipAddress: ''
        })
        setLoading(false)
        setToast({
          status: 'success',
          messages: [`IP Address successfully ${mode ? 'added' : 'deleted'}`]
        })
      } else onError()
    } else onError()
  }

  return (
    <Modal
      header="IP WHITELISTS"
      isOpen={true}
      onClose={() => onClose()}
      onSubmit={handleSubmit}
      submitText={mode ? 'ADD' : 'DELETE'}
    >
      <Box w="full">
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr>
                <Th>IP Address</Th>
                <Th>Date Created</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map(({ ipAddress, fomattedCreatedDate }, index) => (
                <Tr key={`ip-whitelist-${index}`}>
                  <Td>{ipAddress}</Td>
                  <Td>{fomattedCreatedDate}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        {isLoading ? (
          <Center h={20}>
            <Spinner />
          </Center>
        ) : (
          <>
            <Stack direction="row" spacing={2} my={3}>
              <Button
                variant={mode ? 'maroon-mini' : 'outline-mini-black'}
                onClick={() => setMode(1)}
              >
                ADD
              </Button>
              <Button
                variant={mode ? 'outline-mini-black' : 'maroon-mini'}
                onClick={() => setMode(0)}
              >
                DELETE
              </Button>
            </Stack>
            <Stack spacing={3}>
              <StyledInput
                value={inputVal?.pinCode}
                onChange={e =>
                  setInputVal({ ...inputVal, pinCode: e.target.value })
                }
                placeholder="PIN CODE"
                type="password"
              />
              <StyledInput
                value={inputVal?.ipAddress}
                onChange={e =>
                  setInputVal({ ...inputVal, ipAddress: e.target.value })
                }
                placeholder="IP ADDRESS"
                type="text"
              />
            </Stack>
          </>
        )}
      </Box>
    </Modal>
  )
}

export default IPWhitelists
