import { useEffect, useState } from 'react'

import {
  Box,
  Drawer,
  DrawerContent,
  IconButton,
  Flex,
  Image,
  Text
} from '@chakra-ui/react'

import { HamburgerIcon } from '@chakra-ui/icons'

import { getUserBasicInfo, requestLogout } from '../lib/api-helper'

import NavContents from '../components/nav/contents.js'
import { useRanContext } from '../components/ran-state-provider'

import logo from '../assets/images/logo.png'

const NavMenu = () => {
  const {
    isAuthenticated,
    setAuthentication,
    userBasicInfo,
    setUserBasicInfo
  } = useRanContext()
  const [isLoading, setLoading] = useState(false)

  /*--- Nav behavior states ---*/
  const [isLg, setLg] = useState(false)
  const [isOpen, setOpen] = useState(false)

  /*--- JS Nav behavior queries ---*/
  const handleWindowResize = () => {
    const { innerWidth } = window
    if (innerWidth >= 992) {
      setLg(true)
      setOpen(true)
    } else {
      setLg(false)
      setOpen(false)
    }
  }

  useEffect(() => {
    handleWindowResize()
    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize, false)
    }
  }, [])
  /*--- --- --- --- --- --- --- ---*/

  useEffect(() => {
    if (!isAuthenticated) {
      setUserBasicInfo({})
      setLoading(false)
      return
    }

    async function fetchData() {
      await fetchInitialData()
    }

    fetchData()
  }, [isAuthenticated])

  const fetchInitialData = async () => {
    if (isLoading) return
    setLoading(true)

    const result = await getUserBasicInfo(setAuthentication)

    if (result !== null) {
      if (result.status === 200) {
        setUserBasicInfo(result.data)
        setLoading(false)
      }
    }
  }

  const onLogout = async () => {
    setLoading(true)
    const result = requestLogout(setAuthentication)
    if (!result) {
      setLoading(false)
    }
  }

  return (
    <>
      {/* Nav header for small devices */}
      <Flex
        bg="#0e0e0e66"
        color="white"
        display={{ base: 'flex', lg: 'none' }}
        justify="space-between"
        align="center"
        position="fixed"
        w="full"
        p={2}
        px={5}
        css={{ backdropFilter: 'blur(10px)' }}
        zIndex={3}
      >
        <Flex align="center">
          <IconButton
            onClick={() => setOpen(true)}
            icon={<HamburgerIcon />}
            borderRadius={0}
            mr={2}
          />
          <Image src={logo} maxH="30px" />
        </Flex>
        <Text opacity={0.6}>
          {userBasicInfo?.username
            ? `Hi, ${userBasicInfo?.username}`
            : 'Ran Online PH'}
        </Text>
      </Flex>

      {/* Nav menu */}
      {isLg ? (
        <Box h="100vh" w={220} position="fixed" zIndex={4}>
          <NavContents
            userBasicInfo={userBasicInfo}
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
            onLogout={onLogout}
          />
        </Box>
      ) : (
        <Drawer
          isOpen={isLg ? isLg : isOpen}
          onClose={isLg ? null : () => setOpen(false)}
          placement="left"
          blockScrollOnMount={false}
        >
          <DrawerContent maxW={220}>
            <NavContents
              userBasicInfo={userBasicInfo}
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
              onLogout={onLogout}
            />
          </DrawerContent>
        </Drawer>
      )}
    </>
  )
}

export default NavMenu
