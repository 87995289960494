import { Button, Flex, Stack, Text } from '@chakra-ui/react'

const ShopCategories = ({ onChange, paramFilter, shopSelection }) => (
  <Stack mb={4}>
    {shopSelection.map((selection, index) => (
      <Flex
        align="start"
        flexWrap="wrap"
        key={`ranking-${index + 1}`}
        maxW={500}
      >
        <Text
          fontSize={12}
          fontWeight={300}
          w={{ base: 'full', md: 55 }}
          align={{ base: 'left', md: 'right' }}
          mb={{ base: 1, md: 0 }}
        >
          {selection.text}:
        </Text>
        <Flex flex={1} flexWrap="wrap">
          {selection.selection.map((type, _index) => (
            <Button
              key={`ranking-${index + 1}-${_index + 1}`}
              variant={
                paramFilter[selection.key] === type.id
                  ? 'maroon-mini'
                  : 'outline-mini'
              }
              onClick={() => onChange({ [selection.key]: type.id })}
              ml={1}
              mb={1}
            >
              {type.text}
            </Button>
          ))}
        </Flex>
      </Flex>
    ))}
  </Stack>
)

export default ShopCategories
