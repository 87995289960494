import { extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

const styles = {
  global: () => ({
    body: {
      //      bg: mode('light-gray', 'dark-gray')(props)
      bg: 'dark-gray'
    }
  })
}

const fonts = {
  heading: `'NeueHaasUnicaPro', sans-serif`,
  body: `'NeueHaasUnicaPro', sans-serif`
}

const components = {
  Heading: {
    variants: {
      main: {
        borderLeft: '5px solid var(--chakra-colors-maroon)',
        paddingLeft: 2
      }
    }
  },
  Link: {
    baseStyle: {
      _hover: {
        textDecoration: 'underline',
        textUnderlineOffset: 3
      }
    }
  },
  Button: {
    variants: {
      outline: {
        background: 'none',
        display: 'flex',
        h: '43px',
        w: '149px',
        align: 'center',
        fontSize: 12,
        fontWeight: 350,
        color: mode('white', 'black'),
        outline: 'none',
        borderColor: mode('white', 'black'),
        borderRadius: 0,
        boxSizing: 'border-box',
        whiteSpace: 'normal',
        overflow: 'hidden',
        paddingInlineStart: 0,
        paddingInlineEnd: 0,
        _hover: {
          opacity: 0.8
        }
      },
      'outline-mini': {
        background: 'none',
        display: 'flex',
        h: '21px',
        w: 'fit-content',
        align: 'center',
        fontSize: 12,
        fontWeight: 350,
        color: mode('white', 'black'),
        outline: 'none',
        border: '1px solid white',
        borderColor: mode('white', 'black'),
        borderRadius: 0,
        boxSizing: 'border-box',
        whiteSpace: 'normal',
        overflow: 'hidden',
        paddingInlineStart: 3,
        paddingInlineEnd: 3,
        _hover: {
          opacity: 0.8
        }
      },
      'outline-mini-black': {
        background: 'none',
        display: 'flex',
        h: '21px',
        w: 'fit-content',
        align: 'center',
        fontSize: 12,
        fontWeight: 350,
        color: 'black',
        outline: 'none',
        border: '1px solid black',
        borderColor: 'black',
        borderRadius: 0,
        boxSizing: 'border-box',
        whiteSpace: 'normal',
        overflow: 'hidden',
        paddingInlineStart: 3,
        paddingInlineEnd: 3,
        _hover: {
          opacity: 0.8
        }
      },
      maroon: {
        background: 'maroon',
        display: 'flex',
        h: '43px',
        w: '149px',
        align: 'center',
        fontSize: 12,
        fontWeight: 350,
        color: 'white',
        borderRadius: 0,
        boxSizing: 'border-box',
        whiteSpace: 'normal',
        overflow: 'hidden',
        paddingInlineStart: 0,
        paddingInlineEnd: 0,
        _hover: {
          opacity: 0.8
        }
      },
      'maroon-mini': {
        background: 'maroon',
        display: 'flex',
        h: '21px',
        w: 'fit-content',
        align: 'center',
        fontSize: 12,
        fontWeight: 350,
        color: 'white',
        borderRadius: 0,
        boxSizing: 'border-box',
        whiteSpace: 'normal',
        overflow: 'hidden',
        paddingInlineStart: 3,
        paddingInlineEnd: 3,
        _hover: {
          opacity: 0.8
        }
      }
    }
  }
}

const colors = {
  white: '#EEEEEE',
  black: '#0E0E0E',
  'main-red': '#FF0000',
  'main-green': '#008000',
  gold: '#ff8000',
  'main-blue': '#19A6C5',
  maroon: '#911515',
  'light-gray': '#444444',
  'main-gray': '#202020',
  'dark-gray': '#121212'
}

// These are the default breakpoints of chakra-ui in em converted to px
const breakpoints = {
  sm: '480px',
  md: '768px',
  lg: '992px',
  xl: '1280px',
  // All default aside from these for background elements
  '2xl': '1441px'
}

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false
}

const theme = extendTheme({
  breakpoints,
  config,
  components,
  styles,
  colors,
  fonts
})
export default theme
