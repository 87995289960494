import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import ListTicket from './help-desk/list-ticket'
import CreateTicket from './help-desk/create-ticket'
import ViewTicket from './help-desk/view-ticket'

import Modal from '../../modal'

const HelpDesk = ({ onClose, setToast }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [id, setId] = useState(null)

  const onBack = () => navigate('/account?tab=help-desk')

  const onCreate = () => navigate('/account?tab=help-desk&id=new')

  useEffect(() => {
    const param = searchParams.get('id')
    setId(param === 'new' ? 'new' : parseFloat(param))
  }, [location])

  return (
    <Modal
      header={
        !id ? 'HELP DESK' : id === 'new' ? 'CREATE TICKET' : `TICKET #${id}`
      }
      isOpen={true}
      fitContent={!id}
      buttonLess
    >
      {!id ? (
        <ListTicket onClose={onClose} onCreate={onCreate} setToast={setToast} />
      ) : id === 'new' ? (
        <CreateTicket onClose={onBack} setToast={setToast} />
      ) : (
        <ViewTicket onClose={onBack} id={id} setToast={setToast} />
      )}
    </Modal>
  )
}

export default HelpDesk
