import { useEffect } from 'react'

import { Box, Flex } from '@chakra-ui/react'

import Delayed from './delayed'

const Page = props => {
  const maxWidth = props?.narrowProfile ? 760 : 1020

  useEffect(() => {
    document.title = `${props?.title ? props.title + ' - ' : ''}RAN Online PH`
  }, [])

  return (
    <>
      <Box
        w="full"
        color="white"
        pl={{ base: '20px', lg: 240 }}
        pt={{ base: 76, lg: 50 }}
        pr={{ base: '20px', '2xl': 240 }}
        zIndex={2}
      >
        <Delayed>
          <Flex justify={{ base: 'left', '2xl': 'center' }}>
            <Box
              w="full"
              maxW={maxWidth}
              minH={
                props?.highProfile ? 'calc(25vh - 60px)' : 'calc(50vh - 60px)'
              }
            >
              {props?.header}
            </Box>
          </Flex>
        </Delayed>
      </Box>

      <Box
        flex={{ base: null, md: 1 }}
        w={{ base: 'full', md: 'auto' }}
        color="white"
        pr={{ base: '20px', lg: 0 }}
        pl={{ base: '20px', lg: 240 }}
        pb={30}
        zIndex={2}
      >
        <Delayed>
          <Flex justify={{ base: 'left', '2xl': 'center' }}>
            <Box w="full" maxW={maxWidth}>
              {props.children}
            </Box>
          </Flex>
        </Delayed>
      </Box>
    </>
  )
}

export default Page
