import { useState } from 'react'

import { Center, Spinner, Stack } from '@chakra-ui/react'

import Modal from '../../modal'
import StyledInput from '../../styled/input'
import { useRanContext } from '../../ran-state-provider'

import { userMaintenance } from '../../../lib/api-helper'

const ChangePin = ({ onClose, setToast }) => {
  const { setAuthentication } = useRanContext()
  const [isLoading, setLoading] = useState(false)
  const [inputVal, setInputVal] = useState({
    currentPin: '',
    currentPassword: '',
    newPin: '',
    confirmPin: ''
  })

  const handleSubmit = async () => {
    if (isLoading) return
    setLoading(true)

    const result = await userMaintenance('PINCODE', inputVal, setAuthentication)

    const onError = () => {
      setToast({
        status: 'error',
        messages: result?.errors?.length
          ? result.errors
          : ['Something went wrong']
      })
      setLoading(false)
    }

    if (result !== null || result !== undefined) {
      if (result.success) {
        setToast({
          status: 'success',
          messages: ['PIN code has been successfully updated!']
        })
        onClose()
      } else onError()
    } else onError()
  }

  return (
    <Modal
      header="CHANGE PIN"
      isOpen={true}
      isLoading={isLoading}
      onClose={onClose}
      onSubmit={handleSubmit}
    >
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Stack spacing={3} w="full">
          <StyledInput
            value={inputVal?.currentPin}
            onChange={e =>
              setInputVal({ ...inputVal, currentPin: e.target.value })
            }
            placeholder="CURRENT PIN CODE"
            type="password"
          />{' '}
          <StyledInput
            value={inputVal?.currentPassword}
            onChange={e =>
              setInputVal({ ...inputVal, currentPassword: e.target.value })
            }
            placeholder="CURRENT PASSWORD"
            type="password"
          />
          <StyledInput
            value={inputVal?.newPin}
            onChange={e => setInputVal({ ...inputVal, newPin: e.target.value })}
            placeholder="NEW PIN CODE"
            type="password"
          />
          <StyledInput
            value={inputVal?.confirmPin}
            onChange={e =>
              setInputVal({ ...inputVal, confirmPin: e.target.value })
            }
            placeholder="CONFIRM NEW PIN CODE"
            type="password"
          />
        </Stack>
      )}
    </Modal>
  )
}

export default ChangePin
