import { useLocation, Navigate } from 'react-router-dom'

import { Box, Flex } from '@chakra-ui/react'

import NavMenu from './nav-menu'

import Background from '../components/background'
import IndividualRank from '../components/individual-rank'
import ClubWars from '../components/club-wars'
import { useRanContext } from '../components/ran-state-provider'
import SquareAdPreview from '../components/ad-sense/square-ad-preview'
import HorizontalAdPreview from '../components/ad-sense/horizontal-ad-preview'

import mainBackground from '../assets/images/background.jpg'

const Layout = props => {
  const { isAuthenticated } = useRanContext()
  const location = useLocation()

  return (
    <Flex>
      {isAuthenticated === false &&
        (location.pathname === '/account' ||
          location.pathname === '/change-password' ||
          location.pathname === '/helpdesk/create') && <Navigate to="/" />}

      {/* Main background component above, so that below following background components can overlap main */}
      <Background src={mainBackground} />

      <NavMenu />

      {/* Flex wrap for elements to break new line for (full width) header*/}
      {/* zIndex 2 is the base level for elements, same goes for the children of below, because background.js (zIndex upto 1) are passed within */}
      <Flex flexWrap="wrap" w="full" zIndex={2}>
        {props.children}
        <Flex
          direction="column"
          px={'20px'}
          w={{ base: 'full', md: 240 }}
          zIndex={2}
        >
          <Box mb={3}>
            <IndividualRank />
          </Box>

          <Box display={{ base: 'none', md: 'block' }}>
            <SquareAdPreview />
          </Box>
          <Box display={{ base: 'block', md: 'none' }}>
            <HorizontalAdPreview />
          </Box>

          <Box mb="50px">
            <ClubWars />
          </Box>

          <Box display={{ base: 'none', md: 'block' }}>
            <SquareAdPreview />
          </Box>
          <Box display={{ base: 'block', md: 'none' }}>
            <HorizontalAdPreview />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Layout
