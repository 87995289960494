export const storageKeys = {
  accessToken: 'accessToken',
  refreshToken: 'refreshToken',
  isLogin: 'isLogin'
}

const stringToBoolean = string => {
  if (string === null || string === undefined) return false

  switch (string.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true
    case 'false':
    case 'no':
    case '0':
    case null:
      return false
    default:
      return false
  }
}

export const clearTokens = () => {
  sessionStorage.removeItem(storageKeys.accessToken)
  sessionStorage.removeItem(storageKeys.refreshToken)
}

export const clearUserData = () => {
  sessionStorage.removeItem(storageKeys.accessToken)
  sessionStorage.removeItem(storageKeys.refreshToken)
  sessionStorage.removeItem(storageKeys.isLogin)
}

export const saveTokens = tokens => {
  sessionStorage.setItem(storageKeys.accessToken, tokens.accessToken)
}

export const saveLoginStatus = val => {
  sessionStorage.setItem(storageKeys.isLogin, val)
}

export function getToken() {
  return sessionStorage.getItem(storageKeys.accessToken)
}

export function isLogin() {
  return stringToBoolean(sessionStorage.getItem(storageKeys.isLogin))
}
