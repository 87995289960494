import noImageCover from '../assets/images/no-image-cover.jpg'

export function getGreetings() {
  let greetings
  const hourNow = new Date().getHours()
  switch (true) {
    case hourNow > 18:
      greetings = 'Good Evening'
      break
    case hourNow > 12:
      greetings = 'Good Afternoon'
      break
    default:
      greetings = 'Good Morning'
      break
  }
  return greetings
}

export function ordinalSuffixOf(i) {
  var j = i % 10,
    k = i % 100
  if (j == 1 && k != 11) {
    return i + 'st'
  }
  if (j == 2 && k != 12) {
    return i + 'nd'
  }
  if (j == 3 && k != 13) {
    return i + 'rd'
  }
  return i + 'th'
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function titleCase(str) {
  var splitStr = str.toLowerCase().split(' ')
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(' ')
}

export function formatShortDate(dateParam) {
  // returns [Digit Month]/[Day]/[Year]
  const date = new Date(dateParam)
  return (
    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
    '/' +
    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
    '/' +
    date.getFullYear()
  )
}

export function formatLongDate(dateParam) {
  // returns [Full Month] [Day], [Year]
  const date = new Date(dateParam)
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })
}

export function htmlToMinuteRead(strHTML) {
  const cleanText = strHTML.replace(/<\/?[^>]+(>|$)/g, '')
  const minute = cleanText.split(' ').length
  // Below average of native english speaker words read per minute (200)
  const wordsPerMinute = 175
  const result = Math.round(minute / wordsPerMinute)
  return `${result ? result : '<1'} min read`
}

export function htmlToThumbnail(strHTML) {
  // Looks for img elements, filters icons, then return first Image found or Image default cover if NaN
  const sources = strHTML?.match(/<img [^>]*src="[^"]*"[^>]*>/gm)
  const filteredIcons =
    (sources || [])?.filter(img => !/16px/gm.test(img)) || []

  return (
    (filteredIcons?.map(x => x.replace(/.*src="([^"]*)".*/, '$1')))[0] ||
    noImageCover
  )
}
