import { useState } from 'react'

import { Box, Center, Flex, Image, Text } from '@chakra-ui/react'

import Modal from '../modal'
import { useRanContext } from '../ran-state-provider'

import { shopBuyItem } from '../../lib/api-helper'
import { serverItemShopImageStorage } from '../../lib/api-config'

const ShopBuyItem = ({ item, onClose, setToast }) => {
  const { setAuthentication, setUserBasicInfo, userBasicInfo } = useRanContext()
  const [isLoading, setLoading] = useState(false)

  const handleSubmit = async () => {
    if (isLoading) return
    setLoading(true)

    const result = await shopBuyItem({ id: item.productNum }, setAuthentication)

    const onError = () => {
      setToast({
        status: 'error',
        messages: result?.errors?.length
          ? result.errors
          : ['Something went wrong']
      })
      setLoading(false)
    }

    if (result !== null || result !== undefined) {
      if (result.success) {
        const { premiumPoints, gamePoints } = result.data
        setUserBasicInfo({ ...userBasicInfo, premiumPoints, gamePoints })

        setToast({
          status: 'success',
          messages: [
            `Item: ${item.itemName}, successfully purchased!`,
            'You can now use the Item, just go to your Account (B) Bank in-game'
          ]
        })
        onClose()
      } else onError()
    } else onError()
  }

  return (
    <Modal
      header="Item Confirmation"
      isLoading={isLoading}
      isOpen={item}
      onClose={onClose}
      onSubmit={handleSubmit}
      submitText="BUY"
    >
      <Flex align="center" mb={2}>
        <Center overflow="hidden" boxSize={50} mr={3}>
          <Image
            boxSize="full"
            objectFit="cover"
            src={
              serverItemShopImageStorage +
              (item?.itemIco ? item.itemIco : 'default.png')
            }
            alt={item.itemName}
          />
        </Center>
        <Box flex={1}>
          <Text>{item.itemName}</Text>
          <Text
            align="end"
            fontSize={18}
            fontWeight={700}
            color={item.itemSec === 1 ? 'blue' : 'gold'}
          >
            {item.itemPrice} {item.itemSecFormat}
          </Text>
        </Box>
      </Flex>
    </Modal>
  )
}

export default ShopBuyItem
