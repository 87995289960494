import { Link as RouterLink } from 'react-router-dom'

import {
  Box,
  Center,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue
} from '@chakra-ui/react'

//import * as utils from '../lib/function-helper'
import logoutIcon from '../../assets/icons/logout.png'

import { useRanContext } from '../ran-state-provider'

import { requestLogout } from '../../lib/api-helper'

const AccountList = ({ options, isLoading, setLoading }) => {
  const itemBg = useColorModeValue('white', 'main-gray')
  const itemColor = useColorModeValue('black', 'white')
  const iconFilter = useColorModeValue('invert(1)', 'invert(0)')
  const { userBasicInfo, setAuthentication } = useRanContext()

  const onLogout = async () => {
    if (isLoading) return
    setLoading(true)
    const result = await requestLogout(setAuthentication)
    if (!result) setLoading(false)
  }

  return (
    <Box color="white" boxSize="full">
      <Text
        fontSize={14}
        mb={4}
      >{`${userBasicInfo?.username}'s User Panel`}</Text>
      {isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <SimpleGrid minChildWidth={250} spacing={5}>
          {options.map(({ tab, icon, text }, index1) => (
            <Flex
              as={RouterLink}
              to={`/account?tab=${tab}`}
              cursor="pointer"
              p="15px"
              bg={itemBg}
              color={itemColor}
              align="center"
              key={`account-option-${index1}`}
              _hover={{ opacity: 0.6 }}
            >
              <Image boxSize="17px" src={icon} mr={3} filter={iconFilter} />
              <Heading flex={1} fontSize={14}>
                {text}
              </Heading>
            </Flex>
          ))}
          <Flex
            onClick={onLogout}
            cursor="pointer"
            p="15px"
            bg={itemBg}
            color={itemColor}
            align="center"
            _hover={{ opacity: 0.6 }}
          >
            <Image boxSize="20px" src={logoutIcon} mr={3} filter={iconFilter} />
            <Heading flex={1} fontSize={14}>
              LOGOUT
            </Heading>
          </Flex>
        </SimpleGrid>
      )}
    </Box>
  )
}

export default AccountList
