import { useEffect, useState } from 'react'

import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Image,
  Spinner,
  Stack,
  Text,
  useColorModeValue
} from '@chakra-ui/react'

import * as utils from '../lib/function-helper'
import { getRankings } from '../lib/api-helper'
import { getSchoolSmallLogo, getClassImage, getRankImage } from '../lib/ran-helper'

import Page from '../components/page'
import Background from '../components/background'

// import goldTrophy from '../assets/icons/gold.png'
// import silverTrophy from '../assets/icons/silver.png'
// import bronzeTrophy from '../assets/icons/bronze.png'

const Rankings = () => {
  const itemBg = useColorModeValue('white', 'main-gray')
  const itemColor = useColorModeValue('black', 'white')

  const [isLoading, setLoading] = useState(false)
  const [pageTitle, setPageTitle] = useState({
    category: 'Rankings by PK, all schools, and all classes.',
    lastCache: '(Data are cached every one hour)'
  })
  const [data, setData] = useState({
    data: {
      topRanks: []
    }
  })
  const [paramFilter, setParamFilter] = useState({
    type: 'pk',
    school: 'all',
    class: 'all'
  })

  const ranks = [
    'Bronze', 'Silver', 'Gold', 'Platinum', 'Diamond', 'Crown', 'Grandmaster', 'Ace', 'Legendary'
  ]

  const typeSelection = [
    { type: 'pk', text: 'PK' },
    { type: 'level', text: 'Level' },
    { type: 'gold', text: 'Gold' }
    // { type: 'resu', text: 'Resu' }
  ]

  const schoolSelection = [
    { type: 'all', text: 'All' },
    { type: 'sg', text: 'Sacred Gate' },
    { type: 'mp', text: 'Mystic Peak' },
    { type: 'phx', text: 'Phoenix' }
  ]

  const classSelection = [
    { type: 'all', text: 'All' },
    { type: 'brawler', text: 'Brawler' },
    { type: 'swordsman', text: 'Swordsman' },
    { type: 'archer', text: 'Archer' },
    { type: 'shaman', text: 'Shaman' }
    //      {type: "extreme", text: "Extreme"},
    //      {type: "gunner", text: "Gunner"}
  ]

  const updatePageTitle = () => {
    const {
      type: paramType,
      school: paramSchool,
      class: paramClass
    } = paramFilter
    const selectedType = typeSelection.filter(
      ({ type }) => type === paramType
    )[0].text
    const selectedSchool = schoolSelection.filter(
      ({ type }) => type === paramSchool
    )[0].text
    const selectedClass = classSelection.filter(
      ({ type }) => type === paramClass
    )[0].text

    const category = `Rankings by ${selectedType}, ${selectedSchool} school, and ${selectedClass} class.`
    const lastCache = `(Data are cached every one hour.${
      data.data?.lastCachedDate
        ? ` Last cached: ${data.data.lastCachedDate}`
        : ''
    })`

    setPageTitle({ category, lastCache })
  }

  useEffect(() => {
    async function fetchData() {
      await fetchInitialData()
    }

    fetchData()
  }, [paramFilter])

  const fetchInitialData = async () => {
    setLoading(true)
    var result = await getRankings(paramFilter)
    if (result !== null) {
      setData({ data: result.data })
      updatePageTitle()
      setLoading(false)
    }
  }

  const RankingsHeader = () => (
    <Heading variant="main" fontSize={[32]} fontWeight={800} mb={5}>
      RANKINGS
    </Heading>
  )

  const rankingSelection = [
    { text: 'Ranking Type', type: 'type', selection: typeSelection },
    { text: 'School', type: 'school', selection: schoolSelection },
    { text: 'Class', type: 'class', selection: classSelection }
  ]

  const SelectionButtons = () => (
    <Stack mb={4}>
      {rankingSelection.map((selection, index) => (
        <Flex
          align="center"
          flexWrap="wrap"
          key={`ranking-selection-${index + 1}`}
        >
          <Text
            fontSize={12}
            fontWeight={300}
            w={{ base: 'full', md: 20 }}
            align={{ base: 'left', md: 'right' }}
            mb={{ base: 1, md: 0 }}
          >
            {selection.text}:
          </Text>
          <Flex flex={1} flexWrap="wrap">
            {selection.selection.map((type, _index) => (
              <Button
                key={`ranking-${index + 1}-${_index + 1}`}
                variant={
                  paramFilter[selection.type] === type.type
                    ? 'maroon-mini'
                    : 'outline-mini'
                }
                onClick={() =>
                  setParamFilter({
                    ...paramFilter,
                    [selection.type]: type.type
                  })
                }
                ml={1}
                mb={{ base: 1, md: 0 }}
              >
                {type.text}
              </Button>
            ))}
          </Flex>
        </Flex>
      ))}
    </Stack>
  )

  return (
    <>
      <Page
        title="Rankings"
        header={<RankingsHeader />}
        narrowProfile
        highProfile
      >
        <Box boxSize="full">
          <Text fontSize={14} mb={4}>
            {'Top 50 Rankings' || pageTitle.category}{' '}
            <span style={{ fontSize: 12, fontWeight: 300, opacity: 0.6 }}>
              {pageTitle.lastCache}
            </span>
          </Text>

          <SelectionButtons />

          {isLoading ? (
            <Center>
              <Spinner />
            </Center>
          ) : (
            <Stack>
              {data.data.topRanks.map((data, index) => (
                <Flex
                  bg={itemBg}
                  color={itemColor}
                  px="15px"
                  py="10px"
                  align="center"
                  key={`rankings-item-${index}`}
                >
                  <Center w={50} mr="15px" fontSize={16}>
                    {/* {index + 1 === 1 ? (
                      <Image src={goldTrophy} h="46px" />
                    ) : index + 1 === 2 ? (
                      <Image src={silverTrophy} h="44px" />
                    ) : index + 1 === 3 ? (
                      <Image src={bronzeTrophy} h="42px" />
                    ) : (
                      utils.ordinalSuffixOf(index + 1)
                    )} */}
                    {utils.ordinalSuffixOf(index + 1)}
                  </Center>
                  
                  {paramFilter.type === 'pk' && (
                        <Center boxSize={10} mr={3}>
                        <Image src={getRankImage(data.pkRank)} />
                      </Center>
                  )}
                  
                  <Center boxSize={50} mr={3}>
                    <Image src={getClassImage(data.chaClass)} />
                  </Center>
                  <Flex
                    flexWrap="wrap"
                    flex={1}
                    fontSize={14}
                    align="center"
                    justify="space-between"
                  >
                    <Flex align="center" mb={-0.5} mr={2}>
                      <Image
                        h="12px"
                        src={getSchoolSmallLogo(data.chaSchool)}
                        alt={data.schoolName}
                        mr={2}
                      />
                      <Text flex={1} fontSize={16} fontWeight={700}>
                        {data.chaName}
                      </Text>
                    </Flex>

                    <Stack
                      spacing={{ base: 0, md: 8 }}
                      direction={{ base: 'column', md: 'row' }}
                    >
                      {paramFilter.type === 'level' && (
                        <>
                          <Text>
                            Level: <b>{data.chaLevel}</b>
                          </Text>
                          <Text>
                            Level Up Date: <b>{data.chaLastLevelUpDate}</b>
                          </Text>
                        </>
                      )}
                      {paramFilter.type === 'gold' && (
                        <>
                          <Text>
                            Gold:{' '}
                            <span style={{ color: '#ffc107', fontWeight: 700 }}>
                              {data.gold}
                            </span>
                          </Text>
                        </>
                      )}
                      {paramFilter.type === 'pk' && (
                        <>
                          <Text>
                            Rank:{' '}
                            <span style={{ color: 'green', fontWeight: 700 }}>
                              {ranks[data.pkRank]}
                            </span>
                          </Text>
                          <Text>
                            Experience:{' '}
                            <span style={{ color: '#c7e62c', fontWeight: 700 }}>
                              {data.pkExp}
                            </span>
                          </Text>
                        </>
                      )}
                      {paramFilter.type === 'resu' && (
                        <>
                          <Text>
                            PK Resu:{' '}
                            <span style={{ color: 'green', fontWeight: 700 }}>
                              {data.pkResu}
                            </span>
                          </Text>
                          <Text>
                            PK Death:{' '}
                            <span style={{ color: '#dc3545', fontWeight: 700 }}>
                              {data.pkLoss}
                            </span>
                          </Text>
                        </>
                      )}
                    </Stack>
                  </Flex>
                </Flex>
              ))}
            </Stack>
          )}
        </Box>
      </Page>

      <Background highProfile />
    </>
  )
}

export default Rankings
