import { useEffect, useState } from 'react'

import {
  Center,
  Image,
  Flex,
  Stack,
  Spinner,
  Text,
  useColorModeValue
} from '@chakra-ui/react'

import { FaSearch, FaShoppingCart } from 'react-icons/fa'

import ShopCategories from './categories'
import Pagination from '../pagination'
import { useRanContext } from '../ran-state-provider'

import { getShopItems } from '../../lib/api-helper'
import { serverItemShopImageStorage } from '../../lib/api-config'

const ShopList = ({ setPageTitle, onView, onBuy }) => {
  const itemBg = useColorModeValue('white', 'main-gray')
  const itemColor = useColorModeValue('black', 'white')

  const { setAuthentication } = useRanContext()
  const [isLoading, setLoading] = useState(false)

  const [pageNumber, setPageNumber] = useState(1)
  const [data, setData] = useState({
    data: {}
  })
  const [paramFilter, setParamFilter] = useState({
    typeId: 0,
    categoryId: 0
  })

  const typeSelection = [
    { id: 0, text: 'All' },
    { id: 1, text: 'Game Time' },
    { id: 2, text: 'Premium' }
  ]

  const categorySelection = [
    { id: 0, text: 'All' },
    { id: 1, text: 'Head' },
    { id: 2, text: 'Coat' },
    { id: 3, text: 'Pants' },
    { id: 4, text: 'Gloves' },
    { id: 5, text: 'Shoes' },
    { id: 6, text: 'Weapon' },
    { id: 7, text: 'Necklace' },
    { id: 8, text: 'Bracelet' },
    { id: 9, text: 'Ring' },
    { id: 10, text: 'Etc' },
    { id: 11, text: 'Gift Box' },
    { id: 12, text: 'Costumes' }
  ]

  const updatePageTitle = () => {
    const { typeId: paramType, categoryId: paramCategory } = paramFilter

    const selectedType = typeSelection
      .filter(({ id }) => id === paramType)[0]
      .text.toLowerCase()
    const selectedCategory = categorySelection
      .filter(({ id }) => id === paramCategory)[0]
      .text.toLowerCase()

    const newPageTitle = `Shop by ${selectedType} points type and ${selectedCategory} items.`

    setPageTitle(newPageTitle)
  }

  useEffect(() => {
    async function fetchData() {
      await fetchInitialData()
    }

    clearTimeout(localStorage.getItem('shop-list-timer'))
    const timer = setTimeout(fetchData, 100)
    localStorage.setItem('shop-timer', timer)
  }, [paramFilter, pageNumber])

  const fetchInitialData = async () => {
    if (isLoading) return
    setLoading(true)

    var result = await getShopItems(paramFilter, pageNumber, setAuthentication)

    if (result !== null) {
      if (result.status === 200) {
        setData(result.data)
        updatePageTitle()
        setLoading(false)
      }
    }
  }

  const handlePageChange = page => {
    if (page === pageNumber) return
    setPageNumber(page)
  }

  const shopSelection = [
    { text: 'Type', key: 'typeId', selection: typeSelection },
    { text: 'Category', key: 'categoryId', selection: categorySelection }
  ]

  const handleCategoryChange = newParamProp => {
    setPageNumber(1)
    setParamFilter({
      ...paramFilter,
      ...newParamProp
    })
  }

  return (
    <>
      <ShopCategories
        onChange={handleCategoryChange}
        paramFilter={paramFilter}
        shopSelection={shopSelection}
      />

      {isLoading || !data?.dataList ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <>
          {!data?.dataList?.length && 'No items posted in this category...'}
          <Stack>
            {(data.dataList || []).map((data, index) => (
              <Flex
                bg={itemBg}
                color={itemColor}
                px="15px"
                py="10px"
                align="center"
                key={`shop-item-${index}`}
              >
                <Center overflow="hidden" boxSize={50} mr="15px">
                  <Image
                    boxSize="full"
                    objectFit="cover"
                    src={
                      serverItemShopImageStorage +
                      (data?.itemIco ? data.itemIco : 'default.png')
                    }
                    alt={data.itemName}
                  />
                </Center>
                <Flex
                  flexWrap="wrap"
                  flex={1}
                  fontSize={14}
                  align="center"
                  justify="space-between"
                >
                  <Flex
                    flex={1}
                    flexWrap="wrap"
                    justify="space-between"
                    align="center"
                    mr={2}
                  >
                    <Text fontSize={16} fontWeight={400}>
                      {data.itemName}
                    </Text>
                    <Text
                      fontSize={16}
                      fontWeight={700}
                      color={data.itemSec === 1 ? 'blue' : 'gold'}
                    >
                      {data.itemPrice} {data.itemSecFormat}
                    </Text>
                  </Flex>

                  <Stack
                    pl={[2, 8, 8]}
                    spacing={{ base: 0, md: 8 }}
                    direction={{ base: 'column', md: 'row' }}
                    align="start"
                  >
                    <Center
                      onClick={() => onView(data)}
                      cursor="pointer"
                      _hover={{ color: 'maroon' }}
                    >
                      <FaSearch style={{ marginRight: 5 }} />
                      View
                    </Center>
                    <Center
                      onClick={() => onBuy(data)}
                      cursor="pointer"
                      _hover={{ color: 'maroon' }}
                    >
                      <FaShoppingCart style={{ marginRight: 5 }} />
                      Buy
                    </Center>
                  </Stack>
                </Flex>
              </Flex>
            ))}
          </Stack>
        </>
      )}

      <Flex mt={6} w="full" justifyContent="end">
        <Pagination
          value={pageNumber}
          totalPages={data?.totalPages || 0}
          onChange={handlePageChange}
          lightMode
        />
      </Flex>
    </>
  )
}

export default ShopList
