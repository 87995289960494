import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import AnimatedRoutes from './AnimatedRoutes'

import Layout from './compositions/layout'

import { RanStateProvider } from './components/ran-state-provider'
import Chakra from './components/chakra'
import GlobalStyle from './components/global-style'

const App = () => {
  return (
    <Chakra>
      <RanStateProvider>
        <Router>
          <GlobalStyle />

          <Layout>
            <AnimatedRoutes />
          </Layout>
        </Router>
      </RanStateProvider>
    </Chakra>
  )
}

export default App
