import { Box, Center, Flex, Image, Text } from '@chakra-ui/react'

import Modal from '../modal'

import { serverItemShopImageStorage } from '../../lib/api-config'

const ShopBuyItem = ({ item, onClose, onBuy }) => {
  return (
    <Modal
      header={
        <>
          <Center overflow="hidden" boxSize={150} mb={6} mx="auto">
            <Image
              boxSize="full"
              objectFit="cover"
              src={
                serverItemShopImageStorage +
                (item?.itemIco ? item.itemIco : 'default.png')
              }
              alt={item.itemName}
            />
          </Center>
          {item.itemName}
        </>
      }
      isOpen={item}
      onClose={onClose}
      onSubmit={onBuy}
      submitText="BUY"
    >
      <Flex align="end" mb={2} w="full">
        <Box flex={1}>
          <Text maxW={250}>{item.itemComment}</Text>
        </Box>
        <Text
          fontSize={24}
          fontWeight={800}
          color={item.itemSec === 1 ? 'blue' : 'gold'}
        >
          {item.itemPrice} {item.itemSecFormat}
        </Text>
      </Flex>
    </Modal>
  )
}

export default ShopBuyItem
