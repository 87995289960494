import { Textarea } from '@chakra-ui/react'

const StyledTextarea = props => (
  <Textarea
    bg="#0e0e0e25"
    color="black"
    _placeholder={{ fontSize: 12, letterSpacing: 3, color: 'black' }}
    border="none"
    outline="none"
    borderRadius={0}
    fontSize={14}
    letterSpacing={0}
    p="28px"
    resizable="vertical"
    {...props}
  />
)

export default StyledTextarea
