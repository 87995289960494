import View from './tabs/view'
import ChangePassword from './tabs/change-password'
import ChangePin from './tabs/change-pin'
import ForgotPin from './tabs/forgot-pin'
import ChangeEmail from './tabs/change-email'
import HelpDesk from './tabs/help-desk'
import GametimeGamepoints from './tabs/gametime-gamepoints'
import PilotMode from './tabs/pilot-mode'
import IPWhitelists from './tabs/ip-whitelists'
import ShopRecords from './tabs/shop-records'
import ChangeUsername from './tabs/change-username'

const AccountTabs = ({ tab, onClose, setToast }) => {
  const tabProps = { onClose, setToast }

  switch (tab) {
    case 'view':
      return <View {...tabProps} />
    case 'change-username':
        return <ChangeUsername {...tabProps} />
    case 'change-password':
      return <ChangePassword {...tabProps} />
    case 'change-pin':
      return <ChangePin {...tabProps} />
    case 'forgot-pin':
      return <ForgotPin {...tabProps} />
    case 'change-email':
      return <ChangeEmail {...tabProps} />
    case 'help-desk':
      return <HelpDesk {...tabProps} />
    case 'gametime-gamepoints':
      return <GametimeGamepoints {...tabProps} />
    case 'pilot-mode':
      return <PilotMode {...tabProps} />
    case 'ip-whitelists':
      return <IPWhitelists {...tabProps} />
    case 'shop-records':
      return <ShopRecords {...tabProps} />
    default:
      return <></>
  }
}

export default AccountTabs
